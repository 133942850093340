.block-text-with-image-container {
  .container-fluid-image {
    max-height: 40.25rem;
  }
}

.block-text-with-image-container-text-with-background {
  background-size: cover;
}

.block-text-with-image-container-text-background-default {
  @include media-breakpoint-down(md) {
    background-color: $light !important;
    color: $gray-800 !important;
  }
}
