#onetrust-banner-sdk {
  animation-duration: 0s !important;
  animation: none !important;

  &.ot-wo-title.bottom {
    bottom: 0 !important;
  }

  #onetrust-pc-btn-handler,
  #onetrust-reject-all-handler,
  #onetrust-accept-btn-handler {
    &:focus:after {
      transform: translate(-10%, -50%) skew(-30deg);
    }
  }

  a:focus {
    outline-color: $blue !important;
  }
}
