.buorg {
  z-index: $zindex-popover;
  color: $white;
  background-color: $gray-800;
  border: 0;
  font-family: $font-family-base;
  font-size: $font-size-body;
}

.buorg-pad {
  @extend .px-3, .py-3, .line-height-base;
}

.buorg-buttons {
  @extend .mt-3;
}

#buorgul,
#buorgpermanent {
  @extend .btn, .btn-light, .mx-2;
}

#buorgig {
  @extend .btn, .btn-outline-light, .mx-2;
}
