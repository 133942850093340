// workaround for You may not @extend an outer selector from within @media issue
@mixin modal-full-screen() {
  padding: 0 !important;

  .modal-dialog {
    max-width: none !important;
    max-height: none;
    height: 100%;
    width: 100%;
    margin: 0;

    .modal-content {
      border-radius: 0;
      max-height: none;
      height: 100%;
      width: 100%;
    }
  }
}

.modal {
  -webkit-overflow-scrolling: touch;

  .modal-body {
    padding: $modal-inner-padding $modal-inner-padding-x-sm !important;

    @include media-breakpoint-up(lg) {
      padding: $modal-inner-padding !important;
    }
  }
}

.modal-title:first-child {
  margin-top: -0.5rem;
}

.modal-close + .modal-body {
  .modal-title {
    margin-right: 2rem;
  }
}

.modal-close {
  z-index: 1;
  position: absolute;
  top: 1.5rem;
  right: 1rem;

  @include media-breakpoint-up(lg) {
    top: 2.25rem;
    right: 2rem;
  }
}

.modal-dialog-scrollable {
  .modal-body {
    display: flex;
    flex-direction: column;
  }

  .modal-scrollable-content {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.faded-scroll-wrapper {
  position: relative;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;

  &::before,
  &::after {
    opacity: 0;
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    pointer-events: none;
    width: calc(100% - 20px);
    height: 4em;
    @include transition(opacity 0.2s ease);
  }

  &::before {
    top: 0;
    background-image: linear-gradient(to top, rgba($white, 0), $white 90%);
  }

  &::after {
    bottom: 0;
    background-image: linear-gradient(to bottom, rgba($white, 0), $white 90%);
  }

  &.faded-top::before,
  &.faded-bottom::after {
    opacity: 1;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .modal-full-screen#{$infix} {
      @include modal-full-screen();
    }
  }
}

.modal-open {
  padding-right: 0 !important;
}

@include media-breakpoint-up(lg) {
  .modal-xxl {
    max-width: $modal-xxl;
  }
}

.age-gate-modal-backdrop {
  background-color: $white !important;
  opacity: 1 !important;

  @include media-breakpoint-up(lg) {
    background-color: rgba($white, 0.97) !important;
  }
}

.white-overlay {
  position: absolute;
  background-color: $white;
  min-height: 120%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal-backdrop;

  .white-overlay-inner-content {
    position: absolute;
    height: 100vh;
    top: 0;
    right: 0;
    left: 0;
  }
}
