@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix} {
      border: $border-width solid $border-color !important;
    }

    .border#{$infix}-top {
      border-top: $border-width solid $border-color !important;
    }

    .border#{$infix}-right {
      border-right: $border-width solid $border-color !important;
    }

    .border#{$infix}-bottom {
      border-bottom: $border-width solid $border-color !important;
    }

    .border#{$infix}-left {
      border-left: $border-width solid $border-color !important;
    }

    .border#{$infix}-0 {
      border: 0 !important;
    }

    .border#{$infix}-top-0 {
      border-top: 0 !important;
    }

    .border#{$infix}-right-0 {
      border-right: 0 !important;
    }

    .border#{$infix}-bottom-0 {
      border-bottom: 0 !important;
    }

    .border#{$infix}-left-0 {
      border-left: 0 !important;
    }
  }
}

@each $color, $value in $main-colors {
  .border-#{$color} {
    border-color: $value !important;
  }
}

.border-radius-4 {
  border-radius: 4px !important;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.border-radius-lg-10 {
  @include media-breakpoint-up(lg) {
    border-radius: 10px !important;
  }
}

.border-width-3 {
  border-width: 3px !important;
}
