.veev-one-slider {
  .veev-one-swiper-slide {
    border-bottom-right-radius: 50% 45%;
    border-bottom-left-radius: 50% 45%;
  }

  .swiper-slide,
  .veev-one-swiper-slide {
    width: 16.375rem;
    padding-top: 1.625rem;

    overflow: hidden;
    transition: transform 0.9s cubic-bezier(0.2, 0, 0.1, 1), opacity 0.9s cubic-bezier(0.2, 0, 0.1, 1);
    transform: scale(0.9);

    @include media-breakpoint-up(lg) {
      width: 18.75rem;
    }
  }

  .swiper-slide-active {
    opacity: 1;
    transform: scale(1);

    .sensory {
      opacity: 0;
    }

    .veev-badge {
      transform: translate3d(-50%, 1%, 0);
    }
  }

  .veev-badge {
    height: 100%;
    transform: translate3d(-50%, 24%, 0);
    transition: transform 0.9s cubic-bezier(0.2, 0, 0.1, 1), opacity 0.9s;
    position: absolute;
    left: 50%;
    bottom: 0;
    z-index: 4;
  }

  picture:not(.heet-badge) {
    border-radius: 100%;
  }

  .sensory {
    width: 100%;
    transition: opacity 0.9s cubic-bezier(0.2, 0, 0.1, 1);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
  }

  .slide-title {
    display: none;
    @include media-breakpoint-up(md) {
      min-height: 90px;
    }
  }

  .swiper-slide-active .slide-title {
    display: block;
  }

  .veev-one-swiper-buttons-container {
    position: absolute;
    bottom: 10%;
    left: 0;
    right: 0;
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
      width: 45%;
    }

    @include media-breakpoint-up(xl) {
      width: 35%;
    }
  }
}
