$nbw-icon-user: '\e900';
$nbw-icon-cart: '\e901';
$nbw-icon-exit: '\e914';
$nbw-icon-marker: '\e90c';
$nbw-icon-pencil-u: '\e918';
$nbw-icon-exchange: '\e91e';
$nbw-icon-expand: '\e91f';
$nbw-icon-exclamation-mark: '\e91a';
$nbw-icon-filter: '\e90d';
$nbw-icon-eye: '\e910';
$nbw-icon-eye-slash: '\e911';
$nbw-icon-discount-coupon: '\e912';
$nbw-icon-box: '\e919';
$nbw-icon-loudspeaker: '\e920';
$nbw-icon-location-arrow: '\e91b';
$nbw-icon-play: '\e90b';
$nbw-icon-envelope: '\e915';
$nbw-icon-commenting: '\e916';
$nbw-icon-phone-bubble: '\e917';
$nbw-icon-close: '\e902';
$nbw-icon-check: '\e913';
$nbw-icon-plus: '\e90e';
$nbw-icon-minus: '\e90f';
$nbw-icon-arrow-left: '\e903';
$nbw-icon-arrow-right: '\e904';
$nbw-icon-arrow-up: '\e91c';
$nbw-icon-arrow-down: '\e91d';
$nbw-icon-arrow-left-fat: '\e909';
$nbw-icon-arrow-right-fat: '\e90a';
$nbw-icon-chevron-left: '\e905';
$nbw-icon-chevron-right: '\e906';
$nbw-icon-chevron-up: '\e907';
$nbw-icon-chevron-down: '\e908';
$nbw-icon-calendar: '\e921';

.nbw-icon {
  @extend %nbw-icon;
}

.nbw-icon-user {
  &:before {
    content: $nbw-icon-user;
  }
}

.nbw-icon-cart {
  &:before {
    content: $nbw-icon-cart;
  }

  &[data-count] {
    position: relative;

    &::after {
      display: block;
      position: absolute;
      bottom: -0.5em;
      right: -0.3em;
      border-radius: 1.4em;
      background-color: $nbw-icons-accent-bg;
      min-width: 1.4em;
      padding: 0.2em 0.3em;
      font-family: $font-family-base;
      font-size: 0.5em;
      font-weight: $font-weight-bold;
      text-align: center;
      color: $nbw-icons-accent-color;
      content: attr(data-count);
    }
  }
}

.nbw-icon-exit {
  &:before {
    content: $nbw-icon-exit;
  }
}

.nbw-icon-marker {
  &:before {
    content: $nbw-icon-marker;
  }
}

.nbw-icon-pencil-u {
  &:before {
    content: $nbw-icon-pencil-u;
  }
}

.nbw-icon-exchange {
  &:before {
    content: $nbw-icon-exchange;
  }
}

.nbw-icon-expand {
  &:before {
    content: $nbw-icon-expand;
  }
}

.nbw-icon-exclamation-mark {
  &:before {
    content: $nbw-icon-exclamation-mark;
  }
}

.nbw-icon-filter {
  &:before {
    content: $nbw-icon-filter;
  }
}

.nbw-icon-eye {
  &:before {
    content: $nbw-icon-eye;
  }
}

.nbw-icon-eye-slash {
  &:before {
    content: $nbw-icon-eye-slash;
  }
}

.nbw-icon-discount-coupon {
  &:before {
    content: $nbw-icon-discount-coupon;
  }
}

.nbw-icon-box {
  &:before {
    content: $nbw-icon-box;
  }
}

.nbw-icon-loudspeaker {
  &:before {
    content: $nbw-icon-loudspeaker;
  }
}

.nbw-icon-location-arrow {
  &:before {
    content: $nbw-icon-location-arrow;
  }
}

.nbw-icon-play {
  &:before {
    content: $nbw-icon-play;
  }
}

.nbw-icon-envelope {
  &:before {
    content: $nbw-icon-envelope;
  }
}

.nbw-icon-commenting {
  &:before {
    content: $nbw-icon-commenting;
  }
}

.nbw-icon-phone-bubble {
  &:before {
    content: $nbw-icon-phone-bubble;
  }
}

.nbw-icon-check {
  &:before {
    content: $nbw-icon-check;
  }
}

.nbw-icon-close {
  &:before {
    content: $nbw-icon-close;
  }
}

.nbw-icon-plus {
  &:before {
    content: $nbw-icon-plus;
  }
}

.nbw-icon-minus {
  &:before {
    content: $nbw-icon-minus;
  }
}

.nbw-icon-arrow-left {
  &:before {
    content: $nbw-icon-arrow-left;
  }
}

.nbw-icon-arrow-right {
  &:before {
    content: $nbw-icon-arrow-right;
  }
}

.nbw-icon-arrow-up {
  &:before {
    content: $nbw-icon-arrow-up;
  }
}

.nbw-icon-arrow-down {
  &:before {
    content: $nbw-icon-arrow-down;
  }
}

.nbw-icon-arrow-left-fat {
  &:before {
    content: $nbw-icon-arrow-left-fat;
  }
}

.nbw-icon-arrow-right-fat {
  &:before {
    content: $nbw-icon-arrow-right-fat;
  }
}

.nbw-icon-chevron-left {
  &:before {
    content: $nbw-icon-chevron-left;
  }
}

.nbw-icon-chevron-right {
  &:before {
    content: $nbw-icon-chevron-right;
  }
}

.nbw-icon-chevron-up {
  &:before {
    content: $nbw-icon-chevron-up;
  }
}

.nbw-icon-chevron-down {
  &:before {
    content: $nbw-icon-chevron-down;
  }
}

.nbw-icon-calendar {
  &:before {
    content: $nbw-icon-calendar;
  }
}
