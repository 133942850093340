.heets-slider {
  .swiper-slide {
    width: 16.375rem;
    padding-top: 1.625rem;
    border-bottom-right-radius: 50% 45%;
    border-bottom-left-radius: 50% 45%;
    opacity: 0.7;
    overflow: hidden;
    transition: transform 0.9s cubic-bezier(0.2, 0, 0.1, 1), opacity 0.9s cubic-bezier(0.2, 0, 0.1, 1);
    transform: scale(0.9);

    @include media-breakpoint-up(lg) {
      width: 18.75rem;
    }
  }

  .swiper-slide-active {
    opacity: 1;
    transform: scale(1);

    .sensory {
      opacity: 0;
    }

    .heet-badge {
      transform: translate3d(-50%, 1%, 0);
    }
  }

  .heet-badge {
    width: 4.625rem;
    height: 100%;
    transform: translate3d(-50%, 24%, 0);
    transition: transform 0.9s cubic-bezier(0.2, 0, 0.1, 1), opacity 0.9s;
    position: absolute;
    left: 50%;
    bottom: 0;
    z-index: 4;
  }

  picture:not(.heet-badge) {
    border-radius: 100%;
  }

  .sensory {
    width: 100%;
    transition: opacity 0.9s cubic-bezier(0.2, 0, 0.1, 1);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
  }
}
