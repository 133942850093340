.color-select {
  min-height: 1.625rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  a {
    line-height: $color-select-height;

    &:hover {
      text-decoration: none;
    }
  }

  .color-select-item {
    position: relative;
    margin-right: $color-select-input-margin-x;
    width: $color-select-width;
    height: $color-select-height;

    &:last-child {
      margin-right: 0;
    }

    > label {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        border: $color-select-border-width solid transparent;
        border-radius: 50%;
        width: $color-select-width;
        height: $color-select-height;
        transform: translate3d(-50%, -50%, 0);
        transition: border 0.3s;
        content: '';
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 0;
        opacity: 1;
        border-radius: 50%;
        width: $color-select-inner-width;
        height: $color-select-inner-height;
        background: inherit;
        transform: translate(-50%, -50%);
        content: '';
      }
    }

    > input {
      opacity: 0;

      &:focus-visible {
        outline-style: auto;
        outline-offset: 5px;
        outline-color: $outline-color;
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 1;
        width: 0.75rem;
        height: 0.75rem;
        transform: translate(-50%, -50%);
      }

      &:checked ~ label {
        &::before {
          border-color: $color-select-border-color;
        }
      }

      &:not(:checked):hover ~ label {
        &::before {
          border-color: $color-select-border-color-hover;
        }
      }
    }
  }
}

.color-select-item-bordered {
  > label {
    &::after {
      border: $color-select-border-inner-width solid $color-select-border-inner-color;
    }
  }
}

.color-select-large {
  .color-select-item {
    width: $color-select-width-large;
    height: $color-select-height-large;

    > input {
      opacity: 0;

      &:focus-visible {
        outline-offset: 6px;
        width: 1.15rem;
        height: 1.15rem;
      }
    }

    > label {
      &::before {
        width: $color-select-width-large;
        height: $color-select-height-large;
      }

      &::after {
        width: $color-select-inner-width-large;
        height: $color-select-inner-height-large;
      }
    }
  }
}

.color-select-large-and-large-active {
  .color-select-item {
    width: $color-select-width-large-and-large-active;
    height: $color-select-height-large-and-large-active;
    margin-right: $color-select-large-and-large-active-input-margin-x;

    > input {
      opacity: 0;

      &:focus-visible {
        outline-offset: 10px;
        width: 1.15rem;
        height: 1.15rem;
      }
    }

    > label {
      &::before {
        width: $color-select-width-large-and-large-active;
        height: $color-select-width-large-and-large-active;
      }

      &::after {
        width: $color-select-inner-width-large;
        height: $color-select-inner-height-large;
      }
    }
  }
}

.color-select-extra-large {
  .color-select-item {
    width: $color-select-width-extra-large;
    height: $color-select-height-extra-large;

    > label {
      &::before {
        width: $color-select-width-extra-large;
        height: $color-select-height-extra-large;
      }

      &::after {
        width: $color-select-inner-width-extra-large;
        height: $color-select-inner-height-extra-large;
      }
    }
  }
}

.color-list {
  min-height: $color-list-item-sm-height;
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;

  @include media-breakpoint-up(lg) {
    min-height: $color-list-item-height;
  }

  .color-list-item {
    width: $color-list-item-sm-width;
    height: $color-list-item-sm-height;
    border-radius: 50%;

    & + .color-list-item {
      margin-right: -$color-list-item-overlap;
    }

    @include media-breakpoint-up(lg) {
      width: $color-list-item-width;
      height: $color-list-item-height;
    }
  }

  .color-list-item-bordered {
    border: $color-list-item-border;
  }
}
