.navbar-wrapper {
  pointer-events: none;
  z-index: $zindex-navbar;
}

.modal-with-navigation .navbar-wrapper {
  z-index: $zindex-popover;
}

.modal-with-navigation {
  .navbar {
    margin-right: -1rem;
  }

  .modal-dialog {
    $modial-dialog-margin-top: $navbar-small-height + $modal-dialog-margin;
    margin-top: $modial-dialog-margin-top;

    &.modal-dialog-centered {
      min-height: subtract(100%, $modial-dialog-margin-top + $modal-dialog-margin);

      &::before {
        height: subtract(100vh, $modial-dialog-margin-top + $modal-dialog-margin);
      }
    }

    @include media-breakpoint-up($navbar-breakpoint-up) {
      $modial-dialog-margin-top: $navbar-height + $modal-dialog-margin;
      margin-top: $modial-dialog-margin-top;

      &.modal-dialog-centered {
        min-height: subtract(100%, $modial-dialog-margin-top + $modal-dialog-margin);

        &::before {
          height: subtract(100vh, $modial-dialog-margin-top + $modal-dialog-margin);
        }
      }
    }
  }

  &.navbar-promo-active {
    .modal-dialog {
      $modial-dialog-margin-top: $navbar-small-height + $navbar-promo-small-height + $modal-dialog-margin;
      margin-top: $modial-dialog-margin-top;

      &.modal-dialog-centered {
        min-height: subtract(100%, $modial-dialog-margin-top + $modal-dialog-margin);

        &::before {
          height: subtract(100vh, $modial-dialog-margin-top + $modal-dialog-margin);
        }
      }

      @include media-breakpoint-up($navbar-breakpoint-up) {
        $modial-dialog-margin-top: $navbar-small-height + $navbar-promo-small-height + $modal-dialog-margin;
        margin-top: $modial-dialog-margin-top;

        &.modal-dialog-centered {
          min-height: subtract(100%, $modial-dialog-margin-top + $modal-dialog-margin);

          &::before {
            height: subtract(100vh, $modial-dialog-margin-top + $modal-dialog-margin);
          }
        }
      }
    }
  }

  .modal {
    padding-right: 0 !important;
  }
}

.navbar {
  position: relative;
  background-color: $navbar-bg;
  box-shadow: $navbar-box-shadow;
  min-height: $navbar-small-height;
  font-size: $navbar-font-size;
  transition: $navbar-transition;
  pointer-events: auto;

  @include media-breakpoint-up($navbar-breakpoint-up) {
    min-height: $navbar-height;

    .navbar-open & {
      // Hide shadow when navbar is open to properly animate leaving .navbar-collapse-second
      box-shadow: none;
    }
  }

  .scroll-down & {
    transform: translate3d(0, -100%, 0);
    transition: $navbar-transition, opacity 0.15s $navbar-transition-duration linear;
  }

  .navbar-container {
    padding-left: $grid-gutter-width - $navbar-top-nav-link-small-padding-x;
    padding-right: $grid-gutter-width - $navbar-top-nav-link-small-padding-x;

    @include media-breakpoint-up($navbar-breakpoint-up) {
      margin-left: auto;
      margin-right: auto;
      max-width: $navbar-container-max-width;
      padding-left: $grid-gutter-width - $navbar-top-nav-link-padding-x;
      padding-right: $grid-gutter-width - $navbar-top-nav-link-padding-x;
    }
  }
}

.navbar-container {
  display: flex;
  flex-wrap: wrap;

  @include media-breakpoint-up($navbar-breakpoint-up) {
    flex-flow: row nowrap;
  }

  > .navbar-top-nav {
    flex-direction: row;
    font-size: $navbar-top-nav-font-size;

    > .nav-item {
      > .nav-link {
        display: block;
        margin-bottom: 0;
        padding: $navbar-top-nav-link-small-padding-y $navbar-top-nav-link-small-padding-x;
        letter-spacing: $navbar-nav-link-letter-spacing;
        white-space: nowrap;

        @include media-breakpoint-up($navbar-breakpoint-up) {
          padding: $navbar-top-nav-link-padding-y $navbar-top-nav-link-padding-x;
        }
      }

      > .nav-icon {
        padding-top: $navbar-top-nav-icon-small-padding-y;
        padding-bottom: $navbar-top-nav-icon-small-padding-y;
        line-height: 1;

        @include media-breakpoint-up($navbar-breakpoint-up) {
          padding-top: $navbar-top-nav-icon-padding-y;
          padding-bottom: $navbar-top-nav-icon-padding-y;
        }

        .nbw-icon {
          display: block;
        }
      }
    }
  }
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  font-size: $navbar-font-size;

  button.nav-link {
    width: 100%;
    text-align: left;

    @include media-breakpoint-up($navbar-breakpoint-up) {
      display: inline-flex;
    }
  }

  .nav-link {
    display: flex;
    padding: 0;
    color: $navbar-color;

    @include media-breakpoint-up($navbar-breakpoint-up) {
      display: inline-flex;
    }

    .spinner-border-sm {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -$spinner-width-sm / 2;
      margin-top: -$spinner-height-sm / 2;
    }
  }

  .nav-link-title-container {
    flex-grow: 1;
  }

  .nav-link-title {
    display: inline-block;
    position: relative;
    width: 100%;
    font-size: $navbar-nav-link-title-small-font-size;
    font-weight: $navbar-nav-link-title-font-weight;

    @include media-breakpoint-up($navbar-breakpoint-up) {
      display: inline;
      width: auto;
      font-size: $navbar-nav-link-title-font-size;
    }

    &.nav-link-title-with-price {
      font-size: $navbar-nav-link-title-with-price-small-font-size;
      @include media-breakpoint-up($navbar-breakpoint-up) {
        font-size: $navbar-nav-link-title-with-price-font-size;
      }
    }
  }

  .nav-link-extra {
    font-size: $navbar-nav-link-extra-small-font-size;

    @include media-breakpoint-up($navbar-breakpoint-up) {
      font-size: $navbar-nav-link-extra-font-size;
    }
  }

  .nav-link-image {
    align-self: flex-start;
    width: auto;
    max-height: $navbar-nav-link-image-small-height;

    @include media-breakpoint-up($navbar-breakpoint-up) {
      max-height: $navbar-nav-link-image-height;
    }
  }

  .nav-link-image-first-level {
    align-self: flex-start;
    width: auto;
    max-height: $navbar-nav-link-image-first-level-height;
  }

  > .nav-item {
    display: inline;
    margin-bottom: 0;

    > .nav-link {
      margin-bottom: 1.5rem;

      &.nav-link-with-image {
        margin-bottom: 0.5rem;
      }

      @include media-breakpoint-up($navbar-breakpoint-up) {
        &.nav-link-with-image {
          margin-bottom: 1rem;
        }
      }

      &.nav-link-small {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
      }
    }

    &:last-child {
      > .nav-link {
        margin-bottom: 0;
      }
    }
  }
}

.navbar-top-nav {
  > .nav-item {
    > .nav-link.dropdown-toggle::after {
      font-size: 1rem;
      line-height: 1;
      vertical-align: middle;
    }

    &.dropdown > .dropdown-menu {
      margin-top: -1rem;
    }
  }

  @include media-breakpoint-up($navbar-breakpoint-up) {
    flex-direction: row;
    font-size: $navbar-top-nav-font-size;

    > .nav-item {
      > .nav-link {
        z-index: 1; // Pushes this above .navbar-collapse
        position: relative;
        display: block;
        margin-bottom: 0;
        padding: $navbar-top-nav-link-padding-y $navbar-top-nav-link-padding-x;
        letter-spacing: $navbar-nav-link-letter-spacing;
        white-space: nowrap;
        transition: opacity 0.1s linear;

        &.nav-link-small {
          margin-top: 0;
          margin-bottom: 0;
        }

        .nav-link-title {
          font-size: $navbar-top-nav-link-title-font-size;
          font-weight: normal;

          &::before {
            content: none;
          }
        }

        .nav-link-extra {
          display: none;
        }
      }

      &.inactive > .nav-link {
        opacity: $navbar-nav-link-inactive-opacity;
      }
    }
  }

  @media (min-width: $grid-breakpoint-xxl) {
    > .nav-item {
      > .nav-link {
        padding: $navbar-top-nav-link-xxl-padding-y $navbar-top-nav-link-xxl-padding-x;
        font-size: $navbar-top-nav-font-xxl-size;

        .nav-link-title {
          font-size: $navbar-top-nav-font-xxl-size;
        }
      }
    }
  }
}

// Nav link underline
.nav-link-underline {
  @include media-breakpoint-up($navbar-breakpoint-up) {
    .nav-link-title-small {
      position: relative;
      text-decoration: none;

      // Nav link underline
      &::before {
        position: absolute;
        bottom: -($navbar-nav-link-underline-small-width / 2);
        left: 0;
        transform: scaleX(0);
        transform-origin: left center;
        width: 100%;
        height: $navbar-nav-link-underline-small-width;
        background-color: $navbar-nav-link-underline-active-color;
        content: '';
        transition: all 0.25s ease-in-out, background-color 0.2s ease-in-out;
      }
    }

    .nav-link-title {
      text-decoration: none;
      padding-bottom: $navbar-nav-link-underline-width - 2;
      background-color: transparent;
      background-image: linear-gradient(to right, $navbar-nav-link-underline-color 50%, transparent 50%);
      background-size: 200% $navbar-nav-link-underline-width;
      background-position: 100% bottom;
      transition: background-position 0.5s ease-in-out;
      background-repeat: no-repeat;

      &:hover {
        background-position: 0 bottom;
        text-decoration: none;
        transition-delay: 0s !important;
      }
    }
  }
}

// Nav toggle
.nav-toggle {
  position: relative;
  padding-right: 1em;

  @include media-breakpoint-up($navbar-breakpoint-up) {
    padding-right: 0;
  }

  &::after {
    @extend %nbw-icon;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    font-size: inherit;
    line-height: inherit;
    content: $nbw-icon-chevron-right;

    @include media-breakpoint-up($navbar-breakpoint-up) {
      top: auto;
      right: -1.5em;
      margin-top: 0.07em;
      padding-left: 0.5em;
    }

    .navbar-top-nav > .nav-item > .nav-link & {
      @include media-breakpoint-up($navbar-breakpoint-up) {
        position: static;
        top: auto;
        right: auto;
        transform: scaleY(1);
        margin-top: 0;
        margin-left: 0.25em;
        padding-left: 0;
        font-size: 1rem;
        line-height: 1;
        vertical-align: middle;
        content: $nbw-icon-chevron-down;
        transition: transform 0.2s linear;
      }
    }

    .navbar-top-nav > .active > .nav-link & {
      @include media-breakpoint-up($navbar-breakpoint-up) {
        transform: scaleY(-1);
      }
    }
  }
}

// Nav icon
.navbar .nav-icon {
  font-size: $navbar-nav-icon-font-size !important;
}

// Navbar collapse
.navbar-collapse-header {
  display: flex;
  border-bottom: $navbar-collapse-header-border-width solid $navbar-collapse-header-border-color;
  min-height: calc(#{$navbar-small-height} + #{$navbar-collapse-header-border-width});
  padding-right: $navbar-collapse-padding-x - $navbar-btn-padding-x;
  padding-left: $navbar-collapse-padding-x - $navbar-btn-padding-x;

  @include media-breakpoint-up($navbar-breakpoint-up) {
    display: none;
  }
}

.navbar-collapse-brand {
  padding-top: ($navbar-small-height - $navbar-font-size * $line-height-base) / 2;
  padding-bottom: ($navbar-small-height - $navbar-font-size * $line-height-base) / 2;
  color: $navbar-color;
}

.navbar-collapse-body {
  padding: $navbar-collapse-small-padding-y $navbar-collapse-small-padding-x;

  @include media-breakpoint-up($navbar-breakpoint-up) {
    padding: $navbar-collapse-padding-top $navbar-collapse-padding-x $navbar-collapse-padding-bottom;
  }
}

.navbar-collapse-footer {
  margin-top: auto;
  padding: $navbar-collapse-small-padding-y $navbar-collapse-small-padding-x;
  font-size: $navbar-font-size;
  color: $navbar-color;

  @include media-breakpoint-up($navbar-breakpoint-up) {
    display: none !important;
  }

  .navbar-nav {
    .nav-item + .nav-item {
      margin-top: 1rem;
    }

    .nav-link {
      display: inline;
      margin-bottom: 0;
    }
  }

  .dropup,
  .dropdown {
    display: inline-block;
  }
}

@mixin navbar-collapse {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  // fallback for browsers that do not support CSS variables
  height: 100vh;
  // fix for Mobile browsers to get real viewport height (without top and bottom bars)
  height: calc(var(--vh, 1vh) * 100);
  background-color: $navbar-bg;

  .navbar-promo-active & {
    height: calc(100vh - #{$navbar-promo-small-height});
    height: calc(var(--vh, 1vh) * 100 - #{$navbar-promo-small-height});
  }

  @include media-breakpoint-up($navbar-breakpoint-up) {
    height: auto !important;
  }

  @include media-breakpoint-down($navbar-breakpoint-down) {
    z-index: 1;
  }

  &.show {
    display: flex;
  }

  > .navbar-collapse-body {
    overflow-y: auto;

    @include media-breakpoint-up($navbar-breakpoint-up) {
      overflow-y: visible;
    }
  }
}

.navbar-collapse-first {
  @include media-breakpoint-down($navbar-breakpoint-down) {
    @include navbar-collapse;

    &.show {
      display: flex;
    }

    > .navbar-collapse-body {
      padding-bottom: 0;
    }
  }

  @include media-breakpoint-up($navbar-breakpoint-up) {
    display: block !important;
    width: 100%;

    > .navbar-collapse-body {
      padding: 0;
    }
  }
}

.navbar-collapse-second,
.navbar-collapse-third {
  @include navbar-collapse;

  @include media-breakpoint-up($navbar-breakpoint-up) {
    > .navbar-collapse-body {
      display: flex;
      width: 100%;

      > .navbar-nav {
        margin-right: auto;
      }
    }
  }
}

.navbar-collapse-second {
  @include media-breakpoint-up($navbar-breakpoint-up) {
    overflow-y: auto;
    top: $navbar-height;
    min-height: $navbar-collapse-min-height;
    max-height: calc(100vh - #{$navbar-height});
    height: auto;

    .navbar-promo-active & {
      max-height: calc(100vh - #{$navbar-computed-height});
    }

    > .navbar-collapse-body {
      margin-left: auto;
      margin-right: auto;
      max-width: $navbar-container-max-width;
      min-height: $navbar-collapse-min-height;

      > .navbar-nav {
        width: $navbar-collapse-second-width;

        .nav-item:last-child {
          padding-bottom: 1.5rem;
        }
      }
    }
  }

  @include media-breakpoint-down($navbar-breakpoint-down) {
    > .navbar-collapse-body {
      // !important to override min-height for desktop which is applied by javascript
      min-height: 0 !important;
    }
  }
}

.navbar-collapse-third {
  @include media-breakpoint-up($navbar-breakpoint-up) {
    z-index: 2; // moves it above second level collapse
    left: $navbar-collapse-second-width + $navbar-collapse-inner-gutter;
    margin-left: auto;
    margin-right: auto;
    max-width: px-to-rem($navbar-container-max-width) - $navbar-collapse-second-width - $navbar-collapse-inner-gutter;
    min-height: 100%;
    height: auto;

    > .navbar-collapse-body {
      > .navbar-nav {
        flex-grow: 1;
        max-width: calc(100% - #{$navbar-collapse-extra-width});
        padding-right: $navbar-collapse-inner-gutter;

        > .nav-item {
          &.inactive > .nav-link {
            // !important to override opacity applied by animations
            opacity: $navbar-nav-link-inactive-opacity !important;
          }
        }
      }
    }
  }
}

// Navbar collapse animations
.navbar-collapse-first {
  @include media-breakpoint-down($navbar-breakpoint-down) {
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.3s ease;

    > .navbar-collapse-body > .navbar-nav > .nav-item > .nav-link,
    > .navbar-collapse-footer {
      transform: translate3d(-2rem, 0, 0);
      opacity: 0;
      transition: transform 0.3s ease, opacity 0.3s linear;
      transition-delay: 0.3s;
    }

    &.enter-to {
      transform: translate3d(0, 0, 0);

      > .navbar-collapse-body > .navbar-nav > .nav-item > .nav-link,
      > .navbar-collapse-footer {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }
}

.navbar-collapse-second,
.navbar-collapse-third {
  @include media-breakpoint-down($navbar-breakpoint-down) {
    opacity: 0;
    transition: opacity 0.3s linear;

    > .navbar-collapse-body {
      > .navbar-nav > .nav-item > .nav-link,
      > .navbar-collapse-extra {
        transform: translate3d(-2rem, 0, 0);
        opacity: 0;
        transition: transform 0.3s ease, opacity 0.3s linear;
        transition-delay: 0.3s;
      }
    }

    &.enter-to {
      opacity: 1;

      > .navbar-collapse-body {
        > .navbar-nav > .nav-item > .nav-link,
        > .navbar-collapse-extra {
          transform: translate3d(0, 0, 0);
          opacity: 1;
        }
      }
    }

    &.leave-to {
      transition-delay: 0.3s;

      > .navbar-collapse-body {
        > .navbar-nav > .nav-item > .nav-link,
        > .navbar-collapse-extra {
          transition-delay: 0s;
        }
      }
    }

    &.enter-active,
    &.leave-active {
      > .navbar-collapse-body {
        overflow-y: hidden;
      }
    }
  }

  @include media-breakpoint-up($navbar-breakpoint-up) {
    > .navbar-collapse-body {
      > .navbar-nav > .nav-item > .nav-link {
        opacity: 0;
        transform: translate3d(-2rem, 0, 0);
        transition: transform 0.3s ease, opacity 0.2s linear, background-position 0.5s ease-in-out;
      }

      > .navbar-collapse-extra {
        opacity: 0;
        transition: opacity 0.2s linear;
      }
    }

    &.enter-to {
      > .navbar-collapse-body {
        > .navbar-nav > .nav-item > .nav-link {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }

        > .navbar-collapse-extra {
          opacity: 1;
        }
      }
    }
  }
}

.navbar-collapse-second {
  @include media-breakpoint-up($navbar-breakpoint-up) {
    transform: translate3d(0, -100%, 0);
    transition: transform 0.3s ease-in-out;

    > .navbar-collapse-body {
      transition: min-height 0.3s ease-in-out;

      > .navbar-nav > .nav-item > .nav-link {
        transition-delay: 0.3s;
      }

      > .navbar-collapse-extra {
        transition-delay: 0.3s;
      }
    }

    &.enter-to {
      transform: translate3d(0, 0, 0);
    }

    &.leave-to {
      transition-delay: 0.3s;

      > .navbar-collapse-body {
        > .navbar-nav > .nav-item > .nav-link,
        > .navbar-collapse-extra {
          transition-delay: 0s;
        }
      }
    }

    &.enter-active {
      z-index: -2;
    }

    &.leave-active {
      z-index: -1;
    }
  }
}

// Navbar toggler and button
.navbar-toggler {
  position: absolute;
  top: 0;
  left: $grid-gutter-width - $navbar-toggler-padding-x;
  padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
  font-size: $navbar-toggler-font-size;
  line-height: 1;

  @include media-breakpoint-up($navbar-breakpoint-up) {
    display: none;
  }
}

.navbar-toggler-icon {
  display: block;
  width: 1em;
  height: 1em;
  background: escape-svg($navbar-toggler-icon-bg) no-repeat;
  vertical-align: middle;
  content: '';
}

.navbar-toggler,
.navbar-btn {
  background-color: transparent; // remove default button style
  border: 0; // remove default button style

  @include hover-focus() {
    text-decoration: none;
  }
}

.navbar-btn {
  padding: $navbar-btn-padding-y $navbar-btn-padding-x;
  line-height: 1;
  font-size: $navbar-btn-font-size;
}

// Navbar collapse extra
.navbar-collapse-extra {
  position: relative;
  margin-top: 2rem;

  @include media-breakpoint-up($navbar-breakpoint-up) {
    width: $navbar-collapse-extra-width;
    margin-top: 0;
  }

  .nav-link {
    margin-top: 1rem;

    @include media-breakpoint-up($navbar-breakpoint-up) {
      margin-top: 1.25rem;
    }
  }
}

// Navbar brand
.navbar-brand {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: $light;
  padding-top: $navbar-brand-small-padding-y;
  padding-bottom: $navbar-brand-small-padding-y;
  padding-left: $navbar-brand-padding-x;
  padding-right: $navbar-brand-padding-x;

  @include media-breakpoint-up($navbar-breakpoint-up) {
    z-index: 2;
    padding-top: $navbar-brand-padding-y;
    padding-bottom: $navbar-brand-padding-y;
  }

  .brand-image {
    display: block;
    width: auto;
    height: $navbar-brand-small-height;
    max-width: none;

    @include media-breakpoint-up($navbar-breakpoint-up) {
      height: $navbar-brand-height;
    }
  }

  .brand-image-small {
    transition: opacity 0.55s linear;
    opacity: 0;

    .scroll-down & {
      transition-delay: $navbar-transition-duration;
    }

    .scroll-up &,
    .scroll-down & {
      opacity: 1;
    }
  }

  .brand-image-letter {
    transition: opacity 0.33s linear, transform 0.45s ease-out;
    transform: translate3d(0, 0, 0);

    .scroll-down & {
      transition-delay: $navbar-transition-duration;
    }

    .scroll-up &,
    .scroll-down & {
      opacity: 0;

      &.brand-image-letter-i {
        transform: translate3d(35%, 0, 0);
      }

      &.brand-image-letter-q {
        transform: translate3d(14%, 0, 0);
      }

      &.brand-image-letter-o {
        transform: translate3d(14%, 0, 0);
      }

      &.brand-image-letter-s {
        transform: translate3d(-25%, 0, 0);
      }
    }
  }
}

// Navbar backdrop
.navbar-backdrop {
  @extend %backdrop;
  z-index: $zindex-navbar-backdrop;
}

// Navbar open
.navbar-open {
  // Kill the scroll on the body
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    overflow: auto;
  }
}
