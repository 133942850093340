@each $color, $value in $main-colors {
  .bg-#{$color} {
    background-color: $value !important;
  }
}

@each $color, $value in $main-colors {
  .collapse-expanded-bg-#{$color}:not(.collapsed) {
    background-color: $value !important;
  }
}

.bg-lg-transparent {
  @include media-breakpoint-up(lg) {
    background-color: transparent !important;
  }
}
