.heets-variant-body {
  @include media-breakpoint-up(lg) {
    height: auto !important;
  }
}

.heets-header-image {
  @include media-breakpoint-up(lg) {
    max-height: 25rem;
  }
}

.heets-variants-slider-arrows {
  width: 8rem;
}
