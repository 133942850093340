.taxon-description {
  color: $gray-dark;
}

.vertical-menu-mobile-display {
  position: absolute;
  z-index: 2;
  width: 100%;
  background: $gray-100;
  font-size: 1rem;
  border-bottom: 1px solid $gray-light;

  .taxon-title-wrapper {
    margin-right: 1rem;
    max-width: calc(100% - 50px);
  }

  .vertical-menu-wrapper {
    width: 100%;
    margin-top: 2rem;
  }

  .toggle-vertical-menu {
    cursor: pointer;
  }

  .vertical-menu-mobile-icon {
    transition: transform 0.3s;
  }

  &.expanded {
    box-shadow: 0 0.625rem 0.625rem 0 rgba($black, 0.12);

    .vertical-menu-mobile-icon {
      transform: scaleY(-1);
    }
  }
}

.vertical-menu-wrapper {
  display: none;

  &.gift-menu-wrapper {
    display: block;
  }

  @include media-breakpoint-up(lg) {
    display: block;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
}

.sticky-vertical-menu-wrapper {
  position: sticky;
  top: 9rem;
}

.sticky-vertical-menu-wrapper-with-subnav {
  position: sticky;
  top: 12rem;
}
