.cart-item-row {
  .cart-item-image {
    width: auto;
    min-width: 108px;
    max-width: 116px;

    @include media-breakpoint-up(md) {
      min-width: 108px;
      max-width: 150px;
    }

    img {
      width: 92%;
      height: auto;
    }
  }
}

.checkout-payments-images-list {
  img {
    max-height: 89px;
  }
}
