.table {
  border-collapse: collapse;
  border-style: hidden;

  .table-row:first-child {
    .cell {
      border-top: none;
    }
  }

  .title-icon {
    min-width: 34px;

    img {
      height: 34px;
      width: auto;
    }
  }

  .cell {
    border-collapse: collapse;
    border: 1px solid $dark;
    padding: 1rem;
    border-top: 0;
    border-bottom: 0;

    @include media-breakpoint-up(lg) {
      border-top: 1px solid $dark;
      border-bottom: 1px solid $dark;
    }

    .cell-icon {
      max-height: 40px;
      width: auto;
    }
  }

  .table-head > .table-row {
    border-collapse: collapse;

    .cell {
      border: none;
    }
  }

  .table-header-logo {
    max-height: 19px;
    width: auto;

    @include media-breakpoint-up(lg) {
      max-height: 26px;
    }
  }

  .table-body {
    .value-cell {
      vertical-align: middle;
    }
  }

  .table-tag-wrapper {
    width: 36px;

    .table-tag {
      bottom: -0.5rem;
      right: 0;
    }

    @include media-breakpoint-up(lg) {
      width: 0;
    }
  }
}

.table-with-same-width-columns {
  width: 100%;
  table-layout: fixed;
}

.table-with-min-width-columns {
  .cell {
    min-width: 175px;
  }
}
