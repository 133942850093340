.pdp-trade-in-block {
  min-height: 13.5rem;

  @include media-breakpoint-up(lg) {
    min-height: 11rem;
  }
}

.trade-in-modal {
  .trade-in-modal-image {
    width: $sticky-bar-image-height;
    height: auto;
  }
}
