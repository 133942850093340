.terea-structure-image {
  transition: opacity ease 0.3s;
  pointer-events: none;
}

.terea-structure-part-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.terea-structure-button {
  position: absolute;

  .icon {
    pointer-events: none;
    transition: transform ease-in-out 0.2s;
  }
}
