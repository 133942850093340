.block-text-on-color-with-overlay {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.2);
  }
}

.block-text-on-color-container {
  background-position: center;
  background-size: cover;
}

.block-text-on-color-small {
  min-height: 18.75rem;

  @include media-breakpoint-up(lg) {
    min-height: 25rem;
  }
}

.block-text-on-color-large {
  min-height: 38.125rem;
}
