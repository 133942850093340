// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white:    #fff;
$gray-100: #fffdfb;
$gray-200: #f6f4f0;
$gray-250: #EAE8E5;
$gray-300: #e1e0e2;
$gray-400: #b8b7bb;
$gray-500: #99979e;
$gray-600: #7b7881;
$gray-700: #524f5a;
$gray-800: #34303d;
$gray-900: #27242D;
$black:    #000;

$grays: (
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900
);

// NBW colors
$yellow:         #ffd040;
$gold:           #e5b052;
$amber:          #e27d34;
$red:            #db3826;
$deep-red:       #a22f26;
$error-red:      #c73636;
$green:          #57760c;
$blue:           #2980b5;
$blue-dark:      #27529C;
$turquoise:      #00d1d2;
$light-turquoise:#65D6C5;
$dark-turquoise: #005C7D;

// Iluma colors
$iluma-turqoise:       #1accbb;
$iluma-turqoise-alt:   #23DAC5;
$iluma-yellow:         #f9af1b;
$iluma-yellow-alt:     #F8B811;
$iluma-orange:         #E27D34;
$iluma-light-blue:     #00C0FF;
$lil-solid-ez-blue:     #1255A2;
$iluma-neon:            #C400A1;

// Veev colors
$veev-blue-dark:      #017398;
$veev-purple-blue:    #16115B;

// Main colors aliases
$accent:           $turquoise;
$light:            $gray-100;
$gray-extra-light: $gray-200;
$gray-light-lighter: $gray-250;
$gray-light:       $gray-300;
$gray:             $gray-400;
$gray-dark:        $gray-600;
$gray-extra-dark:  $gray-700;
$dark:             $gray-800;
$extra-dark:       $gray-900;

// System colors
$outline-color: -webkit-focus-ring-color;

// Main colors contains available text and background colors
$main-colors: (
  'white': $white,
  'light': $light,
  'gray-extra-light': $gray-extra-light,
  'gray-light': $gray-light,
  'gray-light-lighter': $gray-light-lighter,
  'gray': $gray,
  'gray-dark': $gray-dark,
  'gray-extra-dark': $gray-extra-dark,
  'dark': $dark,
  'extra-dark': $extra-dark,
  'accent': $accent,
  'light-accent': $light-turquoise,
  'amber': $amber,
  'red': $red,
  'green': $green,
  'blue': $blue,
  'blue-dark': $blue-dark,
  'deep-red': $deep-red,
  'error-red': $error-red,
  'yellow': $yellow,
  'dark-turquoise': $dark-turquoise,
  'iluma-turqoise': $iluma-turqoise,
  'iluma-turqoise-alt': $iluma-turqoise-alt,
  'iluma-yellow': $iluma-yellow,
  'iluma-yellow-alt': $iluma-yellow-alt,
  'iluma-orange': $iluma-orange,
  'iluma-light-blue': $iluma-light-blue,
  'lil-solid-ez-blue': $lil-solid-ez-blue,
  'veev-blue-dark': $veev-blue-dark,
  'veev-purple-blue': $veev-purple-blue,
  'iluma-neon': $iluma-neon,
);

// Badge colors
$badge-colors: (
  'dark': $dark,
  'accent': $accent,
  'amber': $amber,
  'blue': $blue,
  'deep-red': $deep-red,
  'error-red': $error-red,
  'yellow': $yellow,
);

// Paragraph background colors
$paragraph-colors: (
  'red': rgba($red, .1),
  'info': rgba(#5FCED0, .1),
  'gray': rgba($gray, .2),
);

// Alert colors
$alert-colors: (
  'danger': $deep-red,
);

// Set a specific jump point for requesting color jumps
$theme-color-interval:      8%;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  145;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $dark;
$yiq-text-light:            $light;

// Characters which are escaped by the escape-svg function
$escaped-characters: (
                ("<","%3c"),
                (">","%3e"),
                ("#","%23"),
);


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:                                true;
$enable-rounded:                              true;
$enable-shadows:                              false;
$enable-gradients:                            false;
$enable-transitions:                          true;
$enable-prefers-reduced-motion-media-query:   true;
$enable-hover-media-query:                    false; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:                         true;
$enable-pointer-cursor-for-buttons:           true;
$enable-print-styles:                         true;
$enable-responsive-font-sizes:                false;
$enable-validation-icons:                     true;
$enable-deprecation-messages:                 true;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
        (
                0: 0,
                1: ($spacer * .25),
                2: ($spacer * .5),
                3: $spacer,
                4: ($spacer * 1.5),
                5: ($spacer * 3),
                6: ($spacer * 4),
                7: ($spacer * 5),
                8: ($spacer * 6),
                9: ($spacer * 7),
                10: ($spacer * 8),
                11: ($spacer * 9),
                12: ($spacer * 10),
        ),
        $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
                (
                        25: 25%,
                        50: 50%,
                        75: 75%,
                        100: 100%,
                        auto: auto
                ),
                $sizes
);


// Body
//
// Settings for the `<body>` element.

$body-bg:                   $gray-100;
$body-color:                $gray-800;

// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15%;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);

// added separately to not break all styles where $grid-breakpoints are used.
// In case of redesign consider adding it to $grid-breakpoints.
$grid-breakpoint-xxl: 1440px;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1374px,
);

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Container narrow

$container-narrow-width: 916px;
$container-extra-narrow-width: 500px;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           2rem;
$grid-gutter-small-width:     1rem;
$grid-row-columns:            6;


// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:              1.5;
$line-height-sm:              1.5;

$border-width:                1px;
$border-color:                $gray-400;

$border-radius:               3px;
$border-radius-lg:            6px;
$border-radius-sm:            3px;

$rounded-pill:                50rem;

$box-shadow-sm:               0 .125rem .25rem rgba($black, .075);
$box-shadow:                  0 .5rem 1rem rgba($black, .15);
$box-shadow-lg:               0 1rem 3rem rgba($black, .175);

$component-active-color:      $white;
$component-active-bg:         $accent;

$caret-width:                 .3em;
$caret-vertical-align:        $caret-width * .85;
$caret-spacing:               $caret-width * .85;

$transition-base:             all .2s ease-in-out;
$transition-fade:             opacity .15s linear;
$transition-collapse:         height .35s ease-in-out;

$embed-responsive-aspect-ratios: ();
// stylelint-disable-next-line scss/dollar-variable-default
$embed-responsive-aspect-ratios: join(
                        (
                                        (21 9),
                                        (16 9),
                                        (4 3),
                                        (1 1),
                        ),
                $embed-responsive-aspect-ratios
);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      'IQOS', 'Helvetica Neue', Arial, sans-serif;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base:              1rem; // `16px`
$font-size-extra-extra-large: $font-size-base * 1.375;
$font-size-extra-large:       $font-size-base * 1.25;
$font-size-large:             $font-size-base * 1.125;
$font-size-body:              $font-size-base;
$font-size-small:             $font-size-base * 0.875;
$font-size-extra-small:       $font-size-base * 0.75;
$font-size-extra-extra-small: $font-size-base * 0.625;

// Font sizes for lg and sm buttons, inputs and etc.
$font-size-lg:                $font-size-extra-large;
$font-size-sm:                $font-size-small;

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;
$font-weight-bolder:          bolder;

$font-weight-base:            $font-weight-normal;
$line-height-small:           1.2;
$line-height-base:            1.5;
$line-height-large:           2;

$letter-spacing-small:        0.03125em;
$letter-spacing-large:        0.2em;
$letter-spacing-nsmall:       -$letter-spacing-small;

$h1-font-size-large:          $font-size-base * 2.5;
$h1-font-size:                $font-size-base * 1.75;
$h2-font-size-large:          $font-size-base * 2;
$h2-font-size:                $font-size-base * 1.625;
$h3-font-size-large:          $font-size-base * 1.75;
$h3-font-size:                $font-size-base * 1.375;
$h4-font-size-large:          $font-size-base * 1.5;
$h4-font-size:                $font-size-base * 1.25;
$h5-font-size-large:          $font-size-base * 1.375;
$h5-font-size:                $font-size-base * 1.125;
$h6-font-size:                $font-size-base;

$headings-margin-bottom:      $spacer / 2;
$headings-font-family:        null;
$headings-font-weight:        $font-weight-bold;
$headings-line-height:        1.2;
$headings-color:              null;
$headings-medium-breakpoint:  md;
$headings-large-breakpoint:   lg;

$display1-size-large:         $font-size-base * 5;
$display1-size-medium:        $font-size-base * 3.75;
$display1-size:               $font-size-base * 2.5;
$display2-size-large:         $font-size-base * 4.0625;
$display2-size-medium:        $font-size-base * 3.125;
$display2-size:               $font-size-base * 2.25;
$display3-size-large:         $font-size-base * 3.5;
$display3-size-medium:        $font-size-base * 2.875;
$display3-size:               $font-size-base * 2;
$display4-size:               $font-size-base * 3;

$display1-weight:             $font-weight-bold;
$display2-weight:             $font-weight-bold;
$display3-weight:             $font-weight-bold;
$display4-weight:             $font-weight-bold;
$display-line-height:         $headings-line-height;

$lead-font-size:              $font-size-large;
$lead-font-weight:            $font-weight-normal;

$small-font-size:             $font-size-small;

$text-muted:                  $gray-500;

$blockquote-small-color:      $gray-600;
$blockquote-small-font-size:  $small-font-size;
$blockquote-font-size:        $font-size-base;

$hr-border-color:             rgba($black, .1);
$hr-border-width:             $border-width;

$mark-padding:                .2em;

$dt-font-weight:              $font-weight-bold;

$kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25);
$nested-kbd-font-weight:      $font-weight-bold;

$list-inline-padding:         .5rem;

$mark-bg:                     #fcf8e3;

$hr-margin-y:                 $spacer;

// Links
//
// Style anchor elements.

$link-color:                              inherit;
$link-decoration:                         none;
$link-hover-color:                        inherit;
$link-hover-decoration:                   underline;
$link-underline-height:                   1px;
$link-underline-large-height:             2px;
$link-arrow-gutter:                       0.5em;
$link-letter-spacing:                     $letter-spacing-small;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:          .75rem;
$table-cell-padding-sm:       .3rem;

$table-color:                 $body-color;
$table-bg:                    null;
$table-accent-bg:             rgba($black, .05);
$table-hover-color:           $table-color;
$table-hover-bg:              rgba($black, .075);
$table-active-bg:             $table-hover-bg;

$table-border-width:          $border-width;
$table-border-color:          $border-color;

$table-head-bg:               $gray-200;
$table-head-color:            $gray-700;
$table-th-font-weight:        null;

$table-dark-color:            $white;
$table-dark-bg:               $gray-800;
$table-dark-accent-bg:        rgba($white, .05);
$table-dark-hover-color:      $table-dark-color;
$table-dark-hover-bg:         rgba($white, .075);
$table-dark-border-color:     lighten($table-dark-bg, 7.5%);

$table-striped-order:         odd;

$table-caption-color:         $text-muted;

$table-bg-level:              -9;
$table-border-level:          -6;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         .5rem;
$input-btn-padding-x:         2rem;
$input-btn-font-family:       null;
$input-btn-font-size:         $font-size-base;
$input-btn-line-height:       $line-height-base;

$input-btn-focus-width:       .2rem;
$input-btn-focus-color:       rgba($component-active-bg, .25);
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm:      .25rem;
$input-btn-padding-x-sm:      .5rem;
$input-btn-font-size-sm:      $font-size-sm;
$input-btn-line-height-sm:    $line-height-sm;

$input-btn-padding-y-lg:      .5rem;
$input-btn-padding-x-lg:      1rem;
$input-btn-font-size-lg:      $font-size-lg;
$input-btn-line-height-lg:    $line-height-lg;

$input-btn-border-width:      2px;
$input-btn-border-width-focus:3px;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               0.625rem;
$btn-padding-x:               2rem;
$btn-font-family:             $font-family-base;
$btn-font-size:               $font-size-base;
$btn-line-height:             $line-height-base;
$btn-white-space:             nowrap;
$btn-letter-spacing:          $letter-spacing-small;

$btn-padding-y-extra-small:   0.1875rem;
$btn-padding-x-extra-small:   0.75rem;
$btn-font-size-extra-small:   $font-size-extra-small;
$btn-line-height-extra-small: $line-height-base;

$btn-padding-y-small:         0.25rem;
$btn-padding-x-small:         1.25rem;
$btn-font-size-small:         $font-size-base;
$btn-line-height-small:       $line-height-base;

$btn-padding-y-large:         0.9375rem;
$btn-padding-x-large:         2rem;
$btn-font-size-large:         $font-size-base;
$btn-line-height-large:       $line-height-base;

$btn-border-width:            $input-btn-border-width;

$btn-font-weight:             $font-weight-normal;
$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075);
$btn-focus-width:             0;
$btn-focus-box-shadow:        0;
$btn-disabled-opacity:        1;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125);

$btn-link-disabled-color:     $gray-500;

$btn-arrow-gutter:            0.5em;
$btn-min-width:               13rem;

$btn-height-extra-small:        $btn-line-height-extra-small * $btn-font-size-extra-small + $btn-padding-y-extra-small * 2;
$btn-height-small:              $btn-line-height-small * $btn-font-size-small + $btn-padding-y-small * 2;
$btn-height:                    $btn-line-height * $btn-font-size + $btn-padding-y * 2;
$btn-height-large:              $btn-line-height-large * $btn-font-size-large + $btn-padding-y-large * 2;

$btn-border-radius-extra-small: $btn-height-extra-small / 2;
$btn-border-radius-small:       $btn-height-small / 2;
$btn-border-radius:             $btn-height / 2;
$btn-border-radius-large:       $btn-height-large / 2;

$btn-transition:                color 0.2s, opacity 0.3s;
$btn-disabled-transition:       background 0.2s, box-shadow 0.2s ease-out, $btn-transition;
$btn-hover-transition:          background 0.2s 0.25s, box-shadow 0.2s 0.25s ease-out, $btn-transition;

$btn-skew-degrees:              -30deg;
$btn-skew-width-increase:       2rem; // depends on skew degrees and height

$btn-colors: (
  'light': (
    'bg': $light,
    'active-bg': $accent,
    'disabled-color': $gray-300,
    'disabled-bg': $gray-600,
  ),
  'light-alt': (
    'bg': $light,
    'active-bg': $dark,
    'disabled-color': $gray-300,
    'disabled-bg': $gray-600,
  ),
  'dark': (
    'bg': $dark,
    'active-bg': $accent,
    'disabled-color': $gray-600,
    'disabled-bg': $gray-400,
  ),
  'dark-alt': (
    'bg': $dark,
    'active-bg': $light,
    'disabled-color': $gray-600,
    'disabled-bg': $gray-400,
  ),
);

// Image select

$image-select-image-sm-height:    2.25rem;
$image-select-image-height:       3.25rem;
$image-select-sm-width:           2rem;
$image-select-sm-height:          3.125rem;
$image-select-width:              3.25rem;
$image-select-height:             4.5rem;
$image-select-inner-width:        .75rem;
$image-select-inner-height:       .75rem;
$image-select-border-width:       2px;
$image-select-border-color:       $dark;
$image-select-border-color-hover: $gray;
$image-select-input-margin-x:     .25rem;
$image-select-input-lg-margin-x:  .25rem;

// Color select

$color-select-width:              1.125rem;
$color-select-height:             1.125rem;
$color-select-width-large:        1.625rem;
$color-select-height-large:       1.625rem;
$color-select-width-large-and-large-active:  2rem;
$color-select-height-large-and-large-active: 2rem;
$color-select-width-extra-large:  2.375rem;
$color-select-height-extra-large: 2.375rem;
$color-select-inner-width:        .75rem;
$color-select-inner-height:       .75rem;
$color-select-inner-width-large:  1.25rem;
$color-select-inner-height-large: 1.25rem;
$color-select-inner-width-extra-large:  1.875rem;
$color-select-inner-height-extra-large: 1.875rem;
$color-select-border-width:       1px;
$color-select-border-color:       $dark;
$color-select-border-color-hover: $gray;
$color-select-border-inner-width: 1px;
$color-select-border-inner-color: $gray;
$color-select-input-margin-x:     .625rem;
$color-select-large-and-large-active-input-margin-x: 0.5rem;

// Color list

$color-list-item-sm-width:      .875rem;
$color-list-item-sm-height:     .875rem;
$color-list-item-width:         1.25rem;
$color-list-item-height:        1.25rem;
$color-list-item-border:        1px solid $gray;
$color-list-item-border-radius: 50%;
$color-list-item-overlap:       .25rem;

// Forms

$input-padding-y:                       0.6875rem;
$input-padding-x:                       1rem;
$input-font-family:                     $input-btn-font-family;
$input-font-size:                       $input-btn-font-size;
$input-font-weight:                     $font-weight-base;
$input-line-height:                     $input-btn-line-height;

$input-padding-y-sm:                    $input-btn-padding-y-sm;
$input-padding-x-sm:                    $input-btn-padding-x-sm;
$input-font-size-sm:                    $input-btn-font-size-sm;
$input-line-height-sm:                  $input-btn-line-height-sm;

$input-padding-y-lg:                    $input-btn-padding-y-lg;
$input-padding-x-lg:                    $input-btn-padding-x-lg;
$input-font-size-lg:                    $input-btn-font-size-lg;
$input-line-height-lg:                  $input-btn-line-height-lg;

$input-bg:                              $gray-100;
$input-disabled-bg:                     $input-bg;
$input-disabled-border-color:           $gray-400;

$input-color:                           $gray-800;
$input-disabled-color:                  $gray-400;
$input-border-color:                    $gray-500;
$input-border-width:                    1px;
$input-box-shadow:                      none;

$input-border-radius:                   $border-radius;
$input-border-radius-lg:                $border-radius-lg;
$input-border-radius-sm:                $border-radius-sm;

$input-active-border-color:             $gray-800;
$input-active-color:                    $gray-800;

$input-focus-bg:                        $input-bg;
$input-focus-border-color:              $input-active-color;
$input-focus-color:                     $input-active-color;
$input-focus-width:                     $input-btn-focus-width;
$input-focus-box-shadow:                inset 0 0 0 $input-border-width $input-focus-border-color;

$input-placeholder-color:               $gray-600;
$input-disabled-placeholder-color:      $gray-400;
$input-plaintext-color:                 $body-color;

$input-height-border:                   $input-border-width * 2;

$input-height-inner:                    add($input-line-height, $input-padding-y * 2);
$input-height-inner-half:               $input-height-inner / 2;
$input-height-inner-quarter:            $input-height-inner / 4;

$input-height:                          add($input-line-height, add($input-padding-y * 2, px-to-rem($input-height-border)));
$input-height-sm:                       add($input-line-height-sm, add($input-padding-y-sm * 2, px-to-rem($input-height-border)));
$input-height-lg:                       add($input-line-height-lg, add($input-padding-y-lg * 2, px-to-rem($input-height-border)));

$input-dropdown-max-lines:              5.5;

$input-icon-font-size:                  1.25rem;
$input-icon-padding:                    0.5rem;

$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out, color .15s ease-in-out;

$label-margin-bottom:                   0.125rem;
$label-font-size:                       $font-size-extra-small;
$label-font-weight:                     $font-weight-normal;
$label-disabled-color:                  $input-disabled-placeholder-color;

$form-floating-label-padding-x:         0.25rem;

$form-text-margin-top:                  .25rem;

$form-check-input-gutter:               1.25rem;
$form-check-input-margin-y:             .3rem;
$form-check-input-margin-x:             .25rem;

$form-check-inline-margin-x:            .75rem;
$form-check-inline-input-margin-x:      .3125rem;

$form-grid-gutter-width:                0.5rem;
$form-group-margin-bottom:              1.5rem;

$custom-forms-transition:               background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$custom-control-gutter:                 0.75rem;
$custom-control-spacer-x:               1rem;
$custom-control-cursor:                 null;

$custom-control-indicator-size:         1.5rem;
$custom-control-indicator-bg:           $input-bg;

$custom-control-indicator-bg-size:      50% 50%;
$custom-control-indicator-box-shadow:   $input-box-shadow;
$custom-control-indicator-border-color: $gray-800;
$custom-control-indicator-border-width: $input-border-width * 2;

$custom-control-label-color:            null;

$custom-control-indicator-disabled-bg:           $input-disabled-bg;
$custom-control-indicator-disabled-border-color: $input-disabled-border-color;
$custom-control-label-disabled-color:            $input-disabled-color;

$custom-control-indicator-checked-color:        $custom-control-indicator-border-color;
$custom-control-indicator-checked-bg:           $custom-control-indicator-bg;
$custom-control-indicator-checked-disabled-bg:  $input-disabled-bg;
$custom-control-indicator-checked-box-shadow:   none;
$custom-control-indicator-checked-border-color: $custom-control-indicator-border-color;

$custom-control-indicator-focus-box-shadow:     none;
$custom-control-indicator-focus-border-color:   $input-focus-border-color;

$custom-control-indicator-active-color:         $custom-control-indicator-border-color;
$custom-control-indicator-active-bg:            $custom-control-indicator-bg;
$custom-control-indicator-active-box-shadow:    none;
$custom-control-indicator-active-border-color:  $custom-control-indicator-border-color;

$custom-checkbox-indicator-checked-bg:           $gray-800;
$custom-checkbox-indicator-checked-color:        $white;
$custom-checkbox-indicator-checked-border-color: $custom-checkbox-indicator-checked-bg;

$custom-checkbox-indicator-bg-size:              58%;
$custom-checkbox-indicator-border-radius:        $border-radius;
$custom-checkbox-indicator-icon-checked:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='17.55' height='13.225' viewBox='0 0 17.55 13.225'><path fill='#{$custom-checkbox-indicator-checked-color}' d='m5.225 13.225c-.2 0-.4-.1-.5-.2l-4.5-4.7c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l4 4.2 10.9-11.2c.3-.3.8-.3 1.1 0s.3.8 0 1.1l-11.5 11.7c-.2.2-.4.2-.6.2z'/></svg>");
$custom-checkbox-indicator-icon-disabled:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='17.55' height='13.225' viewBox='0 0 17.55 13.225'><path fill='#{$custom-control-label-disabled-color}' d='m5.225 13.225c-.2 0-.4-.1-.5-.2l-4.5-4.7c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l4 4.2 10.9-11.2c.3-.3.8-.3 1.1 0s.3.8 0 1.1l-11.5 11.7c-.2.2-.4.2-.6.2z'/></svg>");

$custom-checkbox-indicator-checked-disabled-bg:  $custom-control-indicator-disabled-border-color;

$custom-checkbox-indicator-indeterminate-bg:           $component-active-bg;
$custom-checkbox-indicator-indeterminate-color:        $custom-control-indicator-checked-color;
$custom-checkbox-indicator-icon-indeterminate:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'><path stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/></svg>");
$custom-checkbox-indicator-indeterminate-box-shadow:   none;
$custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg;

$custom-checkbox-circle-indicator-checked-border:         $accent;
$custom-checkbox-circle-indicator-checked-bg:             $accent;
$custom-checkbox-circle-indicator-checked-color:          $dark;
$custom-checkbox-circle-indicator-checked-disabled-color: $light;
$custom-checkbox-circle-indicator-checked-border-color:   $custom-checkbox-indicator-checked-bg;

$custom-checkbox-circle-indicator-border:               1px solid $gray;
$custom-checkbox-circle-indicator-bg-size:              58%;
$custom-checkbox-circle-indicator-border-radius:        50%;
$custom-checkbox-circle-indicator-icon-checked:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='17.55' height='13.225' viewBox='0 0 17.55 13.225'><path fill='#{$custom-checkbox-circle-indicator-checked-color}' d='m5.225 13.225c-.2 0-.4-.1-.5-.2l-4.5-4.7c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l4 4.2 10.9-11.2c.3-.3.8-.3 1.1 0s.3.8 0 1.1l-11.5 11.7c-.2.2-.4.2-.6.2z'/></svg>");
$custom-checkbox-circle-indicator-icon-disabled:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='17.55' height='13.225' viewBox='0 0 17.55 13.225'><path fill='#{$custom-checkbox-circle-indicator-checked-disabled-color}' d='m5.225 13.225c-.2 0-.4-.1-.5-.2l-4.5-4.7c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l4 4.2 10.9-11.2c.3-.3.8-.3 1.1 0s.3.8 0 1.1l-11.5 11.7c-.2.2-.4.2-.6.2z'/></svg>");
$custom-checkbox-circle-outline-width:                  2px;
$custom-checkbox-circle-outline-offset:                 2px;

$custom-checkbox-circle-indicator-checked-disabled-bg:  $custom-control-indicator-disabled-border-color;

$custom-radio-indicator-bg-size:                $custom-control-indicator-bg-size;
$custom-radio-indicator-border-radius:          50%;
$custom-radio-indicator-icon-checked:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='4' fill='#{$custom-control-indicator-checked-color}'/></svg>");
$custom-radio-indicator-checked-disabled-color: $custom-checkbox-indicator-checked-disabled-bg;
$custom-radio-indicator-disabled-icon-checked:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='4' fill='#{$custom-radio-indicator-checked-disabled-color}'/></svg>");

$custom-switch-width:                           $custom-control-indicator-size * 1.75;
$custom-switch-indicator-border-radius:         $custom-control-indicator-size / 2;
$custom-switch-indicator-size:                  subtract($custom-control-indicator-size, $custom-control-indicator-border-width * 4);

$custom-select-padding-y:           $input-padding-y;
$custom-select-padding-x:           $input-padding-x;
$custom-select-font-family:         $input-font-family;
$custom-select-font-size:           $input-font-size;
$custom-select-height:              $input-height;
$custom-select-indicator-padding:   $input-icon-padding;
$custom-select-font-weight:         $input-font-weight;
$custom-select-line-height:         $input-line-height;
$custom-select-color:               $input-color;
$custom-select-disabled-color:      $input-disabled-color;
$custom-select-bg:                  $input-bg;
$custom-select-disabled-bg:         $input-disabled-bg;
$custom-select-bg-size:             $input-icon-font-size; // In pixels because image dimensions
$custom-select-indicator-color:     $input-color;
$custom-select-indicator:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'><path d='m7.914 10.929-4.721-4.721c-.257-.257-.257-.686 0-.944.258-.257.687-.257.944 0l3.863 3.863 3.863-3.863c.257-.257.686-.257.944 0 .257.258.257.687 0 .944z' fill='#{$input-color}'/></svg>");
$custom-select-background:          escape-svg($custom-select-indicator) no-repeat right $custom-select-padding-x center / $custom-select-bg-size; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

$custom-select-feedback-icon-size:          $input-icon-font-size;
$custom-select-feedback-icon-padding:       $input-icon-padding;
$custom-select-feedback-icon-position:      center right $custom-select-padding-x + $custom-select-bg-size + $custom-select-indicator-padding;
$custom-select-feedback-icon-padding-right: $custom-select-padding-x + $custom-select-feedback-icon-size + $custom-select-feedback-icon-padding + $custom-select-bg-size + $custom-select-indicator-padding;

$custom-select-border-width:        $input-border-width;
$custom-select-border-color:        $input-border-color;
$custom-select-border-radius:       $border-radius;
$custom-select-box-shadow:          $input-box-shadow;

$custom-select-focus-border-color:  $input-focus-border-color;
$custom-select-focus-width:         $input-focus-width;
$custom-select-focus-box-shadow:    $input-focus-box-shadow;

$custom-select-padding-y-sm:        $input-padding-y-sm;
$custom-select-padding-x-sm:        $input-padding-x-sm;
$custom-select-font-size-sm:        $input-font-size-sm;
$custom-select-height-sm:           $input-height-sm;

$custom-select-padding-y-lg:        $input-padding-y-lg;
$custom-select-padding-x-lg:        $input-padding-x-lg;
$custom-select-font-size-lg:        $input-font-size-lg;
$custom-select-height-lg:           $input-height-lg;

$custom-range-track-width:          100%;
$custom-range-track-height:         .5rem;
$custom-range-track-cursor:         pointer;
$custom-range-track-bg:             $gray-300;
$custom-range-track-border-radius:  1rem;
$custom-range-track-box-shadow:     inset 0 .25rem .25rem rgba($black, .1);

$custom-range-thumb-width:                   1rem;
$custom-range-thumb-height:                  $custom-range-thumb-width;
$custom-range-thumb-bg:                      $component-active-bg;
$custom-range-thumb-border:                  0;
$custom-range-thumb-border-radius:           1rem;
$custom-range-thumb-box-shadow:              0 .1rem .25rem rgba($black, .1);
$custom-range-thumb-focus-box-shadow:        0 0 0 1px $body-bg, $input-focus-box-shadow;
$custom-range-thumb-focus-box-shadow-width:  $input-focus-width; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg:               lighten($component-active-bg, 35%);
$custom-range-thumb-disabled-bg:             $gray-500;

$custom-file-height:                $input-height;
$custom-file-height-inner:          $input-height-inner;
$custom-file-focus-border-color:    $input-focus-border-color;
$custom-file-focus-box-shadow:      $input-focus-box-shadow;
$custom-file-disabled-bg:           $input-disabled-bg;

$custom-file-padding-y:             $input-padding-y;
$custom-file-padding-x:             $input-padding-x;
$custom-file-line-height:           $input-line-height;
$custom-file-font-family:           $input-font-family;
$custom-file-font-weight:           $input-font-weight;
$custom-file-color:                 $input-color;
$custom-file-bg:                    $input-bg;
$custom-file-border-width:          $input-border-width;
$custom-file-border-color:          $input-border-color;
$custom-file-border-radius:         $input-border-radius;
$custom-file-box-shadow:            $input-box-shadow;
$custom-file-button-color:          $custom-file-color;
$custom-file-button-bg:             $input-border-color;
$custom-file-text: (
        en: "Browse"
);

$form-control-prepend-width: 0.5rem;
$form-control-prepend-padding: 0.5rem;
$form-control-append-width: $form-control-prepend-width;
$form-control-append-padding: $form-control-prepend-padding;
$form-control-prepend-sizes: (1, 2, 3, 4);

// Code input
$code-input-font-size: 1.5rem;
$code-input-font-height: 3rem;
$code-input-font-width: 2.5rem;
$code-input-font-spacing: 1rem;
$code-input-font-mobile-height: 2.75rem;
$code-input-font-mobile-width: 2.2rem;
$code-input-font-mobile-spacing: 0.75rem;

// Form validation

$form-feedback-margin-top:          $form-text-margin-top;
$form-feedback-font-size:           $font-size-small;
$form-feedback-valid-color:         $green;
$form-feedback-invalid-color:       $error-red;

$form-feedback-icon-valid-color:    $form-feedback-valid-color;
$form-feedback-icon-valid:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='m22 12c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10 10 4.477 10 10zm-11.6 3.5 6.9-7c.3-.3.3-.8 0-1.1s-.8-.3-1.1 0l-6.4 6.5-2.2-2.2c-.3-.3-.8-.3-1.1 0s-.3.8 0 1.1l2.7 2.8c.1.1.3.2.5.2s.4-.1.7-.3z' fill='#{$form-feedback-icon-valid-color}'/></svg>") !default;
$form-feedback-icon-invalid-color:  $form-feedback-invalid-color;
$form-feedback-icon-invalid:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path d='m10 20c-5.523 0-10-4.477-10-10s4.477-10 10-10 10 4.477 10 10-4.477 10-10 10zm-1-16v8c0 .552.448 1 1 1s1-.448 1-1v-8c0-.552-.448-1-1-1s-1 .448-1 1zm1 11c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z' fill='#{$form-feedback-icon-invalid-color}'/></svg>") !default;
$form-feedback-icon-size:           $input-icon-font-size;
$form-feedback-icon-padding:        $input-icon-padding;
$form-feedback-icon-checkmark:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><g fill='none' fill-rule='evenodd'><g transform='translate(-272 -20) translate(272 20)'><g filter=''><path d='M0 0H24V24H0z'/><g fill-rule='nonzero' stroke='#FFFDFB' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'><path d='M0 4.223L2.708 7.058 9.578 0' transform='translate(7 8)'/></g></g></g></g></svg>") !default;

$form-validation-states:            ();

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown:                   1000;
$zindex-sticky-nav-backdrop:        1020;
$zindex-sticky:                     1030;
$zindex-fixed:                      1040;
$zindex-modal-backdrop:             1051;
$zindex-modal:                      1052;
$zindex-navbar-backdrop:            1045;
$zindex-navbar:                     1050;
$zindex-popover:                    1060;
$zindex-tooltip:                    1070;


// Navs

$nav-link-padding-y:                0.75rem;
$nav-link-padding-x:                1rem;
$nav-link-disabled-color:           $gray-600;

$nav-tabs-border-color:             transparent;
$nav-tabs-border-width:             $border-width;
$nav-tabs-border-radius:            $border-radius;
$nav-tabs-link-hover-border-color:  $nav-tabs-border-color;
$nav-tabs-link-color:               $white;
$nav-tabs-link-active-color:        $accent;
$nav-tabs-link-bg:                  rgba($black, .6);
$nav-tabs-link-hover-bg:            rgba($black, .8);
$nav-tabs-link-active-bg:           $body-bg;
$nav-tabs-link-active-border-color: $nav-tabs-border-color;

$nav-pills-border-radius:           $border-radius;
$nav-pills-link-active-color:       $component-active-color;
$nav-pills-link-active-bg:          $component-active-bg;

$nav-divider-color:                 $gray-200;
$nav-divider-margin-y:              $spacer / 2;


// Tabs
$tabs-space-between-content:        1rem;
$tabs-padding-x:                    .5rem;
$tabs-link-color:                   $dark;
$tabs-link-text-padding-y:          .25rem;
$tabs-link-text-border:             2px solid $dark;
$tabs-link-description-color:       $gray-dark;
$tabs-link-description-size:        $font-size-small;
$tabs-link-description-padding-y:   .5rem;

// Navbar

$navbar-font-size:                  1rem;
$navbar-box-shadow:                 0 0.125rem 0.375rem rgba($black, 0.05);

$navbar-small-height:               3.5rem;
$navbar-height:                     4.75rem;

$navbar-breakpoint-down:            lg;
$navbar-breakpoint-up:              xl;

$navbar-top-nav-font-xxl-size:          0.875rem;
$navbar-top-nav-font-size:              0.75rem;
$navbar-top-nav-link-xxl-padding-x:     0.5rem;
$navbar-top-nav-link-xxl-padding-y:     ($navbar-height - $navbar-top-nav-font-xxl-size * $line-height-base) / 2;
$navbar-top-nav-link-padding-x:         0.3125rem;
$navbar-top-nav-link-padding-y:         ($navbar-height - $navbar-top-nav-font-size * $line-height-base) / 2;
$navbar-top-nav-link-small-padding-x:   0.625rem;
$navbar-top-nav-link-small-padding-y:   ($navbar-small-height - $navbar-top-nav-font-size * $line-height-base) / 2;
$navbar-top-nav-link-title-font-size:   $navbar-top-nav-font-size;

$navbar-nav-link-letter-spacing:        0.03em;
$navbar-nav-link-title-font-size:       1.5rem;
$navbar-nav-link-title-with-price-font-size:       1rem;
$navbar-nav-link-title-font-weight:     $font-weight-bold;
$navbar-nav-link-title-small-font-size: 1.25rem;
$navbar-nav-link-title-with-price-small-font-size: 1rem;
$navbar-nav-link-extra-font-size:       1rem;
$navbar-nav-link-extra-small-font-size: 0.875rem;
$navbar-nav-link-inactive-opacity:      0.5;

$navbar-nav-link-underline-color:        currentColor;
$navbar-nav-link-underline-active-color: $accent;
$navbar-nav-link-underline-width:        4px;
$navbar-nav-link-underline-small-width:  1px;

$navbar-nav-link-image-small-height:    3.5rem;
$navbar-nav-link-image-height:          4rem;

$navbar-nav-link-image-first-level-height: 1rem;

$navbar-nav-icon-font-size:             1.25rem;

$navbar-top-nav-icon-small-padding-y:   ($navbar-small-height - $navbar-nav-icon-font-size) / 2;
$navbar-top-nav-icon-padding-y:         ($navbar-height - $navbar-nav-icon-font-size) / 2;

$navbar-btn-font-size:                  1.25rem;
$navbar-btn-padding-x:                  1rem;
$navbar-btn-padding-y:                  ($navbar-small-height - $navbar-btn-font-size) / 2;

$navbar-collapse-min-height:            32rem;
$navbar-collapse-padding-top:           3.5rem;
$navbar-collapse-padding-bottom:        3.5rem;
$navbar-collapse-padding-x:             $grid-gutter-width;
$navbar-collapse-small-padding-y:       2rem;
$navbar-collapse-small-padding-x:       $grid-gutter-width;
$navbar-collapse-inner-gutter:          3rem;

$navbar-collapse-header-border-width:   1px;
$navbar-collapse-header-border-color:   $gray-300;

$navbar-collapse-extra-width:           32rem;
$navbar-collapse-second-width:          23rem;

$navbar-brand-small-height:         1.875rem;
$navbar-brand-height:               2.375rem;
$navbar-brand-small-padding-y:      ($navbar-small-height - $navbar-brand-small-height) / 2;
$navbar-brand-padding-x:            0.75rem;
$navbar-brand-padding-y:            ($navbar-height - $navbar-brand-height) / 2;

$navbar-bg:                         $gray-100;
$navbar-color:                      $gray-800;
$navbar-container-max-width:        map-get($container-max-widths, xxl);
$navbar-toggler-font-size:          1.5rem;
$navbar-toggler-icon-bg:            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path stroke='#{$navbar-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M0 7h24M0 17h26'/></svg>");
$navbar-toggler-padding-x:          1rem;
$navbar-toggler-padding-y:          ($navbar-small-height - $navbar-toggler-font-size) / 2;

$navbar-promo-font-size:            $font-size-small;
$navbar-promo-font-weight:          $font-weight-bold;
$navbar-promo-letter-spacing:       $navbar-nav-link-letter-spacing;
$navbar-promo-height:               3.125rem;
$navbar-promo-padding-y:            ($navbar-promo-height - $navbar-promo-font-size * $line-height-base) / 2;
$navbar-promo-bg:                   $dark;
$navbar-promo-color:                $light;

$navbar-promo-small-font-size:      $navbar-promo-font-size;
$navbar-promo-small-height:         $navbar-promo-height;
$navbar-promo-small-padding-y:      ($navbar-promo-small-height - $navbar-promo-small-font-size * $line-height-base) / 2;

$navbar-computed-height:            $navbar-height + $navbar-promo-height;
$navbar-small-computed-height:      $navbar-small-height + $navbar-promo-small-height;

$navbar-backdrop-bg:                $black;
$navbar-backdrop-opacity:           0.7;

$navbar-transition-property:        transform;
$navbar-transition-duration:        0.45s;
$navbar-transition-timing-function: ease;
$navbar-transition:                 $navbar-transition-property $navbar-transition-duration $navbar-transition-timing-function;

// Sub-navbar

$sub-navbar-small-height:               3.5rem;
$sub-navbar-height:                     4.5rem;

$sub-navbar-nav-font-size:              0.875rem;
$sub-navbar-nav-link-padding-x:         1.5rem;
$sub-navbar-nav-link-small-padding-x:   1.25rem;
$sub-navbar-nav-link-padding-y:         ($sub-navbar-height - $sub-navbar-nav-font-size * $line-height-base) / 2;
$sub-navbar-nav-link-small-padding-y:   ($sub-navbar-small-height - $sub-navbar-nav-font-size * $line-height-base) / 2;
$sub-navbar-nav-link-title-font-size:   $sub-navbar-nav-font-size;
$sub-navbar-nav-link-letter-spacing:    0.03em;

$sub-navbar-breakpoint-down:            lg;
$sub-navbar-breakpoint-up:              xl;

$sub-navbar-transition-property:        opacity;
$sub-navbar-transition-duration:        0.3s;
$sub-navbar-transition-timing-function: ease;
$sub-navbar-transition:                 $sub-navbar-transition-property $sub-navbar-transition-duration $sub-navbar-transition-timing-function;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width:                100%;
$dropdown-padding-x:                0;
$dropdown-padding-y:                0.5rem;
$dropdown-spacer:                   0.5rem;
$dropdown-font-size:                $font-size-base;
$dropdown-color:                    $body-color;
$dropdown-bg:                       $gray-100;
$dropdown-border-color:             $dropdown-bg;
$dropdown-border-radius:            3px;
$dropdown-border-width:             0;
$dropdown-inner-border-radius:      subtract($dropdown-border-radius, $dropdown-border-width);
$dropdown-divider-bg:               $gray-200;
$dropdown-divider-margin-y:         $nav-divider-margin-y;
$dropdown-box-shadow:               0 0 3.125rem 0 rgba($black, .1);

$dropdown-link-color:               $gray-800;
$dropdown-link-hover-color:         $dropdown-link-color;
$dropdown-link-hover-bg:            $dropdown-bg;

$dropdown-link-active-color:        $dark;
$dropdown-link-active-bg:           transparent;

$dropdown-link-disabled-color:      $gray-600;

$dropdown-item-padding-y:           0.5rem;
$dropdown-item-padding-x:           1rem;

$dropdown-header-color:             $gray-600;
$dropdown-header-padding:           $dropdown-padding-y $dropdown-item-padding-x;

// Custom dropdown

$custom-dropdown-padding-y:           0.75rem;
$custom-dropdown-padding-x:           0.5rem;

$custom-dropdown-padding-lg-y:        0.75rem;
$custom-dropdown-padding-lg-x:        1.25rem;

// Pagination

$pagination-padding-y:              .125rem;
$pagination-padding-x:              .5rem;
$pagination-padding-y-sm:           .375rem;
$pagination-padding-x-sm:           .75rem;
$pagination-padding-y-lg:           .375rem;
$pagination-padding-x-lg:           .75rem;
$pagination-line-height:            1.25;

$pagination-color:                  $dark;
$pagination-bg:                     $white;
$pagination-border-width:           0;
$pagination-border-color:           transparent;

$pagination-focus-box-shadow:       $input-btn-focus-box-shadow;
$pagination-focus-outline:          $outline-color auto 1px;

$pagination-hover-color:            $dark;
$pagination-hover-bg:               $gray-light;
$pagination-hover-border-color:     transparent;

$pagination-active-color:           $dark;
$pagination-active-bg:              $component-active-bg;
$pagination-active-border-color:    $pagination-active-bg;

$pagination-disabled-color:         $gray-600;
$pagination-disabled-bg:            $white;
$pagination-disabled-border-color:  $gray-300;

$pagination-border-radius-sm:       1rem;
$pagination-border-radius-lg:       1rem;

$pagination-margin:                 .475rem;
$pagination-margin-lg:              .875rem;
$pagination-width:                  1.5rem;
$pagination-width-lg:               2rem;

$pagination-not-active-line-height: 1.35rem;

// Jumbotron

$jumbotron-padding:                 2rem;
$jumbotron-color:                   null;
$jumbotron-bg:                      $gray-200;


// Cards

$card-spacer-y:                     .75rem;
$card-spacer-x:                     1.25rem;
$card-border-width:                 $border-width;
$card-border-radius:                $border-radius;
$card-border-color:                 rgba($black, .125);
$card-inner-border-radius:          subtract($card-border-radius, $card-border-width);
$card-cap-bg:                       rgba($black, .03);
$card-cap-color:                    null;
$card-height:                       null;
$card-color:                        null;
$card-bg:                           $white;

$card-img-overlay-padding:          1.25rem;

$card-group-margin:                 $grid-gutter-width / 2;
$card-deck-margin:                  $card-group-margin;

$card-columns-count:                3;
$card-columns-gap:                  1.25rem;
$card-columns-margin:               $card-spacer-y;


// Tooltips

$tooltip-font-size:                 0.75rem;
$tooltip-max-width:                 200px;
$tooltip-color:                     $white;
$tooltip-bg:                        $gray-800;
$tooltip-border-radius:             4px;
$tooltip-opacity:                   1;
$tooltip-padding-y:                 .375rem;
$tooltip-padding-x:                 .625rem;
$tooltip-margin:                    2px;

$tooltip-arrow-width:               .5rem;
$tooltip-arrow-height:              .25rem;
$tooltip-arrow-color:               $tooltip-bg;

// Popovers

$popover-font-size:                 $font-size-sm;
$popover-bg:                        $light;
$popover-max-width:                 276px;
$popover-border-width:              0;
$popover-border-color:              $border-color;
$popover-border-radius:             $border-radius;
$popover-inner-border-radius:       subtract($popover-border-radius, $popover-border-width);
$popover-box-shadow:                0 0.125rem 0.375rem rgba($black, 0.05);

$popover-header-bg:                 $light;
$popover-header-color:              $headings-color;
$popover-header-padding-top:        1rem;
$popover-header-padding-bottom:     0.5rem;
$popover-header-padding-x:          1.125rem;

$popover-body-color:                $body-color;
$popover-body-padding-bottom:       $popover-header-padding-top;
$popover-body-padding-top:          $popover-header-padding-bottom;
$popover-body-padding-x:            $popover-header-padding-x;

$popover-arrow-width:               1.5rem;
$popover-arrow-height:              .75rem;
$popover-arrow-color:               $popover-bg;

$popover-arrow-outer-color:         fade-in($popover-border-color, .05);


// Toasts

$toast-width:                       18.75rem;
$toast-max-width:                   40rem;
$toast-padding-x:                   2rem;
$toast-padding-y:                   1.7rem;
$toast-margin:                      0 0 0.5rem;
$toast-font-size:                   .875rem;
$toast-title-font-size:             $font-size-extra-large;
$toast-color:                       $dark;
$toast-background-color:            $light;
$toast-border-width:                1px;
$toast-border-color:                $gray-extra-light;
$toast-border-radius:               0;
$toast-box-shadow:                  0 2px 10px rgba(0, 0, 0, 0.12);
$toast-close-btn-padding:           0.25rem 0;
$toast-icon-color:                  $white;
$toast-success-icon-bg-color:       $green;
$toast-error-icon-bg-color:         $error-red;

$toast-header-color:                $gray-600;
$toast-header-background-color:     rgba($white, .85);
$toast-header-border-color:         rgba(0, 0, 0, .05);


// Badges

$badge-font-size:                   0.75rem;
$badge-font-weight:                 $font-weight-normal;
$badge-letter-spacing:              $letter-spacing-small;
$badge-padding-y:                   0.5em;
$badge-padding-x:                   1em;
$badge-border-radius:               0.125rem;
$badge-border-width:                1px;
$badge-bigger-border-radius:        0.25rem;
$badge-bigger-font-size:            1rem;
$badge-bigger-padding-y:            0.375em;
$badge-bigger-padding-x:            0.5em;

$badge-transition:                  $btn-transition;
$badge-focus-width:                 $input-btn-focus-width;

$badge-pill-padding-x:              0.8em;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius:          10rem;


// Modals

// Padding applied to the modal body
$modal-inner-padding:               2.5rem;
$modal-inner-padding-x-sm:          1.5rem;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between:       .5rem;

$modal-dialog-margin:               2rem;
$modal-dialog-margin-y-sm-up:       1.75rem;

$modal-title-line-height:           $line-height-base;

$modal-content-color:               null;
$modal-content-bg:                  $light;
$modal-content-border-color:        $border-color;
$modal-content-border-width:        0;
$modal-content-border-radius:       0;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width);
$modal-content-box-shadow-xs:       0 .25rem .5rem rgba($black, .5);
$modal-content-box-shadow-sm-up:    0 .5rem 0.875rem rgba($black, .06);

$modal-backdrop-bg:                 $gray-700;
$modal-backdrop-opacity:            0.85;
$modal-header-border-color:         $border-color;
$modal-footer-border-color:         $modal-header-border-color;
$modal-header-border-width:         $modal-content-border-width;
$modal-footer-border-width:         $modal-header-border-width;
$modal-header-padding-y:            1rem;
$modal-header-padding-x:            1rem;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility

$modal-xxl:                         972px;
$modal-xl:                          840px;
$modal-lg:                          640px;
$modal-md:                          496px;
$modal-sm:                          300px;

$modal-fade-transform:              translate(0, -50px);
$modal-show-transform:              none;
$modal-transition:                  transform .3s ease-out;
$modal-scale-transform:             scale(1.02);


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y:                   .75rem;
$alert-padding-x:                   1.25rem;
$alert-margin-bottom:               2rem;
$alert-border-radius:               $border-radius;
$alert-link-font-weight:            $font-weight-bold;
$alert-border-width:                $border-width;

$alert-bg-level:                    -10;
$alert-border-level:                -9;
$alert-color-level:                 6;


// Progress bars

$progress-height:                   1rem;
$progress-font-size:                $font-size-base * .75;
$progress-bg:                       $gray-200;
$progress-border-radius:            $border-radius;
$progress-box-shadow:               inset 0 .1rem .1rem rgba($black, .1);
$progress-bar-color:                $white;
$progress-bar-bg:                   $dark;
$progress-bar-animation-timing:     1s linear infinite;
$progress-bar-transition:           width .6s ease;


// List group

$list-group-color:                     $body-color;
$list-group-bg:                        $light;
$list-group-border-color:              $gray-200;
$list-group-border-width:              $border-width;
$list-group-border-radius:             0;

$list-group-item-padding-y:            0.75rem;
$list-group-item-padding-x:            1.25rem;
$list-group-item-arrow-font-size:      $font-size-extra-large;

$list-group-hover-bg:                  $list-group-bg;
$list-group-active-color:              $list-group-color;
$list-group-active-arrow-color:        $accent;
$list-group-active-bg:                 $gray-200;
$list-group-active-border-color:       $list-group-active-bg;

$list-group-disabled-color:            $gray-600;
$list-group-disabled-bg:               $list-group-bg;

$list-group-action-color:              $list-group-color;
$list-group-action-hover-color:        $list-group-active-color;
$list-group-action-hover-arrow-color:  $list-group-active-arrow-color;

$list-group-action-active-color:       $list-group-active-color;
$list-group-action-active-arrow-color: $list-group-action-hover-arrow-color;
$list-group-action-active-bg:          $list-group-active-bg;

// Image thumbnails

$thumbnail-padding:                 .25rem;
$thumbnail-bg:                      $body-bg;
$thumbnail-border-width:            $border-width;
$thumbnail-border-color:            $gray-300;
$thumbnail-border-radius:           $border-radius;
$thumbnail-box-shadow:              0 1px 2px rgba($black, .075);


// Figures

$figure-caption-font-size:          90%;
$figure-caption-color:              $gray-600;


// Breadcrumbs

$breadcrumb-font-size:              null;

$breadcrumb-padding-y:              .75rem;
$breadcrumb-padding-x:              1rem;
$breadcrumb-item-padding:           .5rem;

$breadcrumb-margin-bottom:          1rem;

$breadcrumb-bg:                     $gray-200;
$breadcrumb-divider-color:          $gray-600;
$breadcrumb-active-color:           $gray-600;
$breadcrumb-divider:                quote("/");

$breadcrumb-border-radius:          $border-radius;


// Carousel

$carousel-control-color:             $white;
$carousel-control-width:             15%;
$carousel-control-opacity:           .5;
$carousel-control-hover-opacity:     .9;
$carousel-control-transition:        opacity .15s ease;

$carousel-indicator-width:           30px;
$carousel-indicator-height:          3px;
$carousel-indicator-hit-area-height: 10px;
$carousel-indicator-spacer:          3px;
$carousel-indicator-active-bg:       $white;
$carousel-indicator-transition:      opacity .6s ease;

$carousel-caption-width:             70%;
$carousel-caption-color:             $white;

$carousel-control-icon-width:        20px;

$carousel-control-prev-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/></svg>");
$carousel-control-next-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/></svg>");

$carousel-transition-duration:       .6s;
$carousel-transition:                transform $carousel-transition-duration ease-in-out; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)


// Spinners

$spinner-width:                   2rem;
$spinner-height:                  $spinner-width;
$spinner-border-width:            .25em;
$spinner-border-secondary-color:  rgba($dark, 0.07);

$spinner-width-sm:                1rem;
$spinner-height-sm:               $spinner-width-sm;
$spinner-border-width-sm:         .2em;

$spinner-width-lg:                4rem;
$spinner-height-lg:               $spinner-width-lg;
$spinner-border-width-lg:         .25em;

$spinner-width-btn:               $btn-font-size * $btn-line-height;
$spinner-height-btn:              $spinner-width-btn;
$spinner-border-width-btn:        .2em;

// Close

$close-font-size:                   $font-size-base * 1.5;
$close-font-weight:                 $font-weight-bold;
$close-color:                       $black;
$close-text-shadow:                 0 1px 0 $white;

// Media

$media-body-font-size: $font-size-small;


// Code

$code-font-size:                    87.5%;
$code-color:                        #e83e8c;

$kbd-padding-y:                     .2rem;
$kbd-padding-x:                     .4rem;
$kbd-font-size:                     $code-font-size;
$kbd-color:                         $white;
$kbd-bg:                            $gray-900;

$pre-color:                         $gray-900;
$pre-scrollable-max-height:         340px;


// Utilities

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex;
$overflows: auto, hidden;
$positions: static, relative, absolute, fixed, sticky;
$user-selects: all, auto, none;


// Printing

$print-page-size:                   a3;
$print-body-min-width:              map-get($grid-breakpoints, "lg");



// Custom overrides

// Icons config.

$nbw-icons-font-family:   'nbw-icons';
$nbw-icons-font-path:     '../../fonts/nbw-icons';
$nbw-icons-accent-bg:     $accent;
$nbw-icons-accent-color:  color-yiq($nbw-icons-accent-bg);

// Steppers
$stepper-li-a-padding: 1.5rem;
$stepper-li-a-circle-color: $white;
$stepper-li-a-circle-bg: rgba($black, .38);
$stepper-li-a-circle-mr: .5rem;
$stepper-li-a-label-color: rgba($black, .87);

$stepper-horizontal-li-transition: .5s;
$stepper-horizontal-li-a-label-mt: .63rem;
$stepper-horizontal-li-after-margin: .5rem;
$stepper-horizontal-li-after-height: 1px;
$stepper-horizontal-li-after-bgc: rgba($black, .1);
$stepper-horizontal-breakpoint: 47.9375rem;
$stepper-horizontal-small-li-a-label-mt: .2rem;
$stepper-horizontal-small-li-after-width: $stepper-horizontal-li-after-height;
$stepper-horizontal-small-li-after-height: calc(100% - 40px);
$stepper-horizontal-small-li-after-left: 2.19rem;
$stepper-horizontal-small-li-after-top: 3.75rem;

$stepper-vertical-li-a-label-mt: $stepper-horizontal-small-li-a-label-mt;
$stepper-vertical-li-step-content-ml: 3.13rem;
$stepper-vertical-li-step-content-padding: .94rem;
$stepper-vertical-li-step-content-p-font-size: .88rem;
$stepper-vertical-li-after-width: 1px;
$stepper-vertical-li-after-height: $stepper-horizontal-small-li-after-height;
$stepper-vertical-li-after-left: $stepper-horizontal-small-li-after-left;
$stepper-vertical-li-after-top: 3.44rem;
$stepper-vertical-li-after-bgc: $stepper-horizontal-li-after-bgc;

// Map
$map-content-bg: $light;
$map-content-width: 21rem;
$map-content-min-height: 13.5rem;
$map-content-body-padding-top-small: 2rem;
$map-content-body-padding-x-small: $grid-gutter-width;
$map-content-body-padding-top: 1.25rem;
$map-content-body-padding-x: 1.25rem;
$map-content-offset-top: 1.25rem;
$map-content-offset-bottom: 1.25rem;
$map-content-offset-left: 4rem;
$map-content-box-shadow: 0 0.125rem 0.25rem 0 rgba($black, 0.2);

$map-controls-offset-small: 2rem;
$map-controls-offset: 1.25rem;

$map-info-window-bg: $light;
$map-info-window-width: 21rem;
$map-info-window-padding-x: 1.25rem;
$map-info-window-padding-y: 1.25rem;
$map-info-window-box-shadow: $map-content-box-shadow;
$map-info-window-close-offset: 3rem;

$map-floating-marker-width: 2.75rem;
$map-floating-marker-height: $map-floating-marker-width;
$map-floating-marker-color: $dark;
$map-floating-marker-font-size: 0.625rem;

// Scrollbar

$scrollbar-track-color: $gray-100;
$scrollbar-thumb-color: $gray-400;

// Circle icon

$circle-icon-width: 2.5rem;
$circle-icon-height: $circle-icon-width;
$circle-icon-font-size: 1rem;
$circle-icon-bg: $gray-extra-light;
$circle-icon-color: $body-color;

$circle-icon-width-small: 1.5rem;
$circle-icon-height-small: $circle-icon-width-small;
$circle-icon-font-size-small: 0.875rem;

$circle-icon-width-extra-small: 1.25rem;
$circle-icon-height-extra-small: $circle-icon-width-extra-small;
$circle-icon-font-size-extra-small: 0.75rem;

// Radio checker

$radio-checker-button-width: 1.25rem;
$radio-checker-button-height: 1.25rem;
$radio-checker-border-color: $gray-extra-light;
$radio-checker-background: $gray-extra-light;
$radio-checker-active-background: $accent;
$radio-checker-font-size: $font-size-body;
$radio-checker-font-color: $body-color;

// Paragraph block

$paragraph-block-border-width: 2px;
$paragraph-block-border-color: rgba($black, 0.2);
$paragraph-block-background-color: rgba($black, 0.1);
$paragraph-block-padding-y: 0.5rem;
$paragraph-block-padding-x: 1.075rem;
$paragraph-block-border-radius: 4px;

// Collapse item

$collapse-item-border-width: $border-width;
$collapse-item-border-color: $border-color;
$collapse-item-padding-y: 2rem;
$collapse-item-padding-y-small: 1.5rem;
$collapse-item-with-circle-border-color: $gray-800;
$collapse-item-with-circle-border-width: 2px;
$collapse-item-with-circle-height: 2rem;
$collapse-item-with-circle-width: 2rem;

// Ribbon

$ribbon-color: $accent;
$ribbon-height: 10.625rem;
$ribbon-width: $ribbon-height * 0.65;
$ribbon-mobile-height: 7rem;
$ribbon-mobile-width: $ribbon-mobile-height * 0.66;

// Milestone slider

$milestone-image-height: 5.375rem;
$milestone-large-image-height: 19rem;

// Seperator

$seperator-width: 3rem;
$seperator-height: 0.375rem;
$seperator-color: $turquoise;

// Sticky bar

$sticky-bar-top-box-shadow: 0 0.125rem 0.375rem rgba($black, 0.05);
$sticky-bar-bottom-box-shadow: 0 -0.125rem 0.375rem rgba($black, 0.05);
$sticky-bar-transition: $navbar-transition, $sub-navbar-transition;
$sticky-bar-transition-duration: $navbar-transition-duration;
$sticky-bar-image-height: 2.75rem;
$sticky-bar-image-height-lg: 3.75rem;

// Cart popup

$cart-popup-width: 25rem;
$cart-popup-box-shadow: -0.125rem -0.125rem 0.375rem rgba($black, 0.05);
$cart-popup-large-breakpoint: lg;
$cart-popup-body-padding-x: 3rem;
$cart-popup-body-padding-y: $cart-popup-body-padding-x;
$cart-popup-body-padding-small-x: 2rem;
$cart-popup-body-padding-small-y: $cart-popup-body-padding-small-x;
$cart-popup-footer-padding-x: 1.25rem;
$cart-popup-footer-padding-bottom: 1.25rem;
$cart-popup-footer-padding-small-x: 0;
$cart-popup-footer-padding-small-bottom: 0;
$cart-popup-transition: $navbar-transition, padding-top $navbar-transition-duration $navbar-transition-timing-function;
$cart-popup-transition-duration: $navbar-transition-duration;

// fullscreen popup

$fullscreen-popup-transition: $navbar-transition, padding-top $navbar-transition-duration $navbar-transition-timing-function;
$fullscreen-popup-transition-duration: $navbar-transition-duration;

// Swiper

$swiper-button-font-size: 2.5rem;
$swiper-button-light-color: $light;
$swiper-button-light-hover-color: $swiper-button-light-color;
$swiper-button-dark-color: $dark;
$swiper-button-dark-hover-color: $swiper-button-dark-color;
$swiper-button-transition: color 0.2s, opacity 0.3s;

$swiper-pagination-dark-bg: rgba($gray-500, 0.4);
$swiper-pagination-dark-active-bg: $dark;
$swiper-pagination-light-bg: rgba($light, 0.3);
$swiper-pagination-light-active-bg: $light;
$swiper-pagination-bullet-width: 0.5rem;
$swiper-pagination-bullet-height: $swiper-pagination-bullet-width;
$swiper-pagination-bullet-gap: 0.75rem;
$swiper-pagination-stretched-bullet-width: 1.375rem;
$swiper-pagination-stretched-bullet-height: 0.125rem;
$swiper-pagination-stretched-bullet-gap: 0.875rem;
$swiper-pagination-stretched-bullet-width-small: 1rem;
$swiper-pagination-stretched-bullet-height-small: 0.125rem;
$swiper-pagination-stretched-bullet-gap-small: 0.75rem;

// Dots

$dot-size: 0.625rem;
$dot-lg-size: 1.125rem;
$dot-color: $gray-100;

$dots-group-dot-spacer: 0.625rem;
$dots-group-dot-narrow-spacer: 0.375rem;

$dots-pill-bg: $white;
$dots-pill-padding-x: 0.4375rem;
$dots-pill-padding-y: $dots-pill-padding-x;
$dots-pill-dot-size: 0.375rem;
$dots-pill-dot-spacer: 0.25rem;
$dots-pill-dot-color: $body-color;
$dots-pill-dot-opacity: 0.4;
$dots-pill-dot-active-opacity: 1;
$dots-pill-border-radius: ($dots-pill-padding-y * 2 + $dots-pill-dot-size) / 2;
$dots-pill-opaque-bg: rgba($white, 0.2);
$dots-pill-opaque-dot-color: $white;

// How product works

$how-product-works-bg: $dark;
$how-product-works-color: color-yiq($dark);
$how-product-works-container-height: 55%;
$how-product-works-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);

// Scene progress

$scene-progress-bar-width: 0.125rem;
$scene-progress-bar-height: 1.375rem;
$scene-progress-bar-gap: 0.875rem;
$scene-progress-bar-height-small: 1rem;
$scene-progress-bar-gap-small: 0.75rem;

$scene-progress-bar-dark-bg: rgba($gray-800, 0.3);
$scene-progress-bar-dark-active-bg: $gray-800;

$scene-progress-bar-light-bg: rgba($light, 0.2);
$scene-progress-bar-light-active-bg: $light;

// Large nav block

$large-nav-block-item-padding-x: 0.315rem;
$large-nav-block-item-padding-y: 0.438rem;
$large-nav-block-item-border-width: 2px;
$large-nav-block-width: 28.75rem;

// Code block

$code-block-small-padding-y: 0.313rem;
$code-block-small-padding-x: 0.625rem;
$code-block-padding-y: 0.75rem;
$code-block-padding-x: 1rem;
$code-block-background-color: rgba($light, 0.25);

// Ordered list (large)

$ordered-list-bullet-gap: 1.5rem;
$ordered-list-bullet-gap-small: 1rem;
$ordered-list-bullet-width: 2.125rem;
$ordered-list-bullet-height: $ordered-list-bullet-width;
$ordered-list-bullet-background: $dark;
$ordered-list-bullet-color: $white;

// Pointer

$pointer-width: 1.75rem;
$pointer-height: $pointer-width;
$pointer-bg: $gray-extra-dark;
$pointer-bg-active: $accent;
$pointer-outline-width: 3.75rem;
$pointer-outline-height: $pointer-outline-width;
$pointer-outline-border-width: 1px;
$pointer-outline-border-color: $light;
$pointer-line-height: 1px;
$pointer-line-color: $gray-extra-dark;
$pointer-line-offset: -20rem;

// Timer

$timer-bg: $gray-300;
$timer-color: $body-color;
$timer-border-radius: 0.25rem;
$timer-block-padding-x: 0.5rem;
$timer-block-padding-y: 0.75rem;
$timer-col-gap: 0.75rem;
$timer-value-min-width: 2em;
$timer-value-padding-x: 0.5rem;
$timer-value-font-size: 1.5rem;
$timer-value-font-weight: $font-weight-bold;
$timer-label-font-size: $font-size-small;
$timer-value-padding-y: 1rem;
$timer-value-item-min-width: 2.5em;
$timer-value-item-font-size: 1.5rem;
$timer-value-item-font-weight: $font-weight-bold;
$timer-value-item-line-height: 1;
$timer-value-label-font-size: $font-size-extra-small;
$timer-value-label-line-height: $headings-line-height;
$timer-value-item-in-square-min-width: 3.75rem;
$timer-value-item-in-square-min-width-lg: 5.5rem;
$timer-value-item-in-square-font-size: $h1-font-size;
$timer-value-item-in-square-font-size-lg: $h1-font-size-large;

// Collapsible footer

$collapsible-footer-toggle-font-size: $font-size-base;
$collapsible-footer-toggle-font-weight: $font-weight-bold;
$collapsible-footer-toggle-padding-y: 0.5rem;
$collapsible-footer-toggle-border-width: 1px;
$collapsible-footer-toggle-border-color: $gray-extra-dark;
$collapsible-footer-toggle-height: $collapsible-footer-toggle-font-size * $line-height-base +
  $collapsible-footer-toggle-padding-y * 2 + px-to-rem($collapsible-footer-toggle-border-width);
$collapsible-footer-toggle-bg: $dark;
$collapsible-footer-toggle-color: color-yiq($collapsible-footer-toggle-bg);

// Floating message

$floating-message-breakpoint-up: lg;
$floating-message-x-padding: 2rem;
$floating-message-y-padding: 1.5rem;
$floating-message-x-padding-mobile: 1.25rem;
$floating-message-y-padding-mobile: 1rem;
$floating-message-margins-mobile: 1.25rem;
$floating-message-offset-right: 2.5rem;
$floating-message-offset-bottom: 2.5rem;
$floating-message-box-shadow: 0 0.5rem 0.875rem rgba($black, 0.05);
$floating-message-width: 25rem;
$floating-message-border-radius: 4px;

// Bubbles slider

$bubbles-slider-slide-size: 18.75rem;
$bubbles-slider-slide-size-small: 16.375rem;
$bubbles-slider-slide-padding-top: $bubbles-slider-slide-size * 0.2;
$bubbles-slider-slide-padding-top-small: $bubbles-slider-slide-size-small * 0.2;
$bubbles-slider-transition-timing-function: cubic-bezier(0.2, 0, 0.1, 1);

// Checkout message

$checkout-message-bg: $accent;
$checkout-message-padding-x: $grid-gutter-width;
$checkout-message-padding-y: 1rem;
$checkout-message-up-padding-y: 1rem;
$checkout-message-up-padding-x: 1.25rem;
$checkout-message-border-radius: 0.1875rem;
$checkout-message-product-list-bg: $light;
$checkout-message-product-list-border-radius: 0.375rem;
$checkout-message-product-image-widht: 5.25rem;
$checkout-message-breakpoint-down: md;
$checkout-message-breakpoint-up: lg;
$checkout-message-floating-width: 22.5rem;
$checkout-message-floating-offset-right: 1.25rem;
$checkout-message-floating-offset-bottom: 1.25rem;
$checkout-message-floating-box-shadow: 0 0.5rem 0.875rem rgba($black, 0.05);

// Floating message

$floating-message-breakpoint-up: lg;
$floating-message-x-padding: 2rem;
$floating-message-y-padding: 1.5rem;
$floating-message-x-padding-mobile: 1.25rem;
$floating-message-y-padding-mobile: 1rem;
$floating-message-margins-mobile: 1.25rem;
$floating-message-offset-right: 2.5rem;
$floating-message-offset-bottom: 2.5rem;
$floating-message-box-shadow: 0 0.5rem 0.875rem rgba($black, 0.05);
$floating-message-width: 25rem;
$floating-message-border-radius: 4px;

// Linear range

$linear-range-item-height: 10px;
$linear-range-item-width: 25px;
$linear-range-item-space: 2px;
$linear-range-item-radius: 5px;

// Circular range

$circular-range-height: 41px;
$circular-range-width: 44px;
$circular-range-part-height: 44px;
$circular-range-part-width: 44px;
$circular-range-border-radius: 22px;
$circular-range-part-clip: rect(38px, 22px, 44px, 9px);
$circular-range-part-angle: 29deg;
$circular-range-small-height: 30px;
$circular-range-small-width: 36px;
$circular-range-part-small-height: 34px;
$circular-range-part-small-width: 36px;
$circular-range-small-radius: 30px;
$circular-range-small-part-clip: rect(30px, 24px, 35px, 15px);
$circular-range-small-part-angle: 31deg;
$circular-range-border: 2px solid $accent;
$circular-range-background-border: 2px solid $gray-extra-light;
