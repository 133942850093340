.timer-container {
  display: flex;
}

.timer {
  display: flex;
  margin-left: -$timer-col-gap / 2;
  margin-right: -$timer-col-gap / 2;
}

.timer-col {
  padding-left: $timer-col-gap / 2;
  padding-right: $timer-col-gap / 2;
}

.timer-block {
  border-radius: $timer-border-radius;
  background-color: $timer-bg;
  padding: $timer-block-padding-y $timer-block-padding-x;
  text-align: center;
}

.timer-value {
  min-width: $timer-value-min-width;
  padding-left: $timer-value-padding-x;
  padding-right: $timer-value-padding-x;
  font-size: $timer-value-font-size;
  font-weight: $timer-value-font-weight;
}

.timer-label {
  font-size: $timer-label-font-size;
}

.timer-value-item-in-square {
  min-width: $timer-value-item-in-square-min-width;
  font-size: $timer-value-item-in-square-font-size;
  font-weight: $timer-value-item-font-weight;
  line-height: $timer-value-item-line-height;

  @include media-breakpoint-up(lg) {
    min-width: $timer-value-item-in-square-min-width-lg;
    font-size: $timer-value-item-in-square-font-size-lg;
  }
}
