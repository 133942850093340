.pagination {
  .page-item {
    margin-left: $pagination-margin;
    margin-right: $pagination-margin;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
      margin-left: $pagination-margin-lg;
      margin-right: $pagination-margin-lg;
    }

    .page-link {
      position: relative;
      width: $pagination-width;
      padding: $pagination-padding-y $pagination-padding-x;
      border-radius: $pagination-border-radius-sm;

      .pagination-icon {
        position: relative;
        left: -50%;
      }
    }

    &.active .page-link {
      width: $pagination-width-lg;
      padding: $pagination-padding-y-lg $pagination-padding-x-lg;
      border-radius: $pagination-border-radius-lg;
    }

    &:not(.active) .page-link {
      line-height: $pagination-not-active-line-height;
    }

    &:first-child .page-link {
      border-top-left-radius: $pagination-border-radius-lg;
      border-bottom-left-radius: $pagination-border-radius-lg;
    }

    &:last-child .page-link {
      border-top-right-radius: $pagination-border-radius-lg;
      border-bottom-right-radius: $pagination-border-radius-lg;
    }
  }
}
