.navbar-promo {
  position: relative;
  z-index: 1; // pushes navbar-promo above navbar-collapse content
  background-color: $navbar-promo-bg;
  padding-top: $navbar-promo-small-padding-y;
  padding-bottom: $navbar-promo-small-padding-y;
  color: $navbar-promo-color;
  text-align: center;
  font-size: $navbar-promo-small-font-size;
  font-weight: $navbar-promo-font-weight;
  letter-spacing: $navbar-promo-letter-spacing;
  pointer-events: auto;

  @include media-breakpoint-up(xl) {
    padding-top: $navbar-promo-padding-y;
    padding-bottom: $navbar-promo-padding-y;
    font-size: $navbar-promo-font-size;
  }
}

.navbar-promo-container {
  position: relative;
}

.navbar-promo-close {
  position: absolute;
  top: 50%;
  right: $grid-gutter-width - ($btn-height-small - $btn-font-size-small) / 2;
  transform: translateY(-50%);

  &:focus-visible {
    outline: auto !important;
    outline-color: $outline-color !important;
  }
}
